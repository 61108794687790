;__browserify_shim_require__=require;(function browserifyShim(module, exports, require, define, browserify_shim__define__module__export__) {
(function(api, $, undefined) {

	var state = {},

		settings = {},

		dom = {

			document: $(document),
			brand: $("#branding")

		},

		setupInitialState = function(logo, brand){

			settings.logo = logo;
			settings.brand = brand;
			settings.delayBeforeFading = 5000;

			state.fadeTimeout = undefined;

		},

		addEventHandlers = function(){

			dom.document.on("mouseover", "#branding", function() {

				window.clearTimeout(state.fadeTimeout);
				revealElement(dom.brand, 50);

			});

			dom.document.on("mouseout", "#branding", function(){
				initiateFadeTimer(dom.brand, 1000);
			});

			dom.document.on("click", "#branding", function(){
				goToPhotoBiz();
			});

		},

		goToPhotoBiz = function(){
			window.open("http://photobiz.com");
		},

		revealElement = function(el, duration){

			el.stop(true, true).animate({ opacity: 1 }, duration, function(){
				initiateFadeTimer(dom.brand, 1000);
			});

		},

		initiateFadeTimer = function(el, duration){

			state.fadeTimeout = window.setTimeout(function() {

				hideElement(el, duration);

			}, settings.delayBeforeFading);

		},

		hideElement = function(el, duration){

			el.stop(true, true).animate({

				opacity: 0

			}, duration);

		};

	api.init = function(logo, brand){

		setupInitialState(logo, brand);

		addEventHandlers();

		revealElement(dom.brand, 1000);

	};

}( window.fader = window.fader || {}, jQuery ));
; browserify_shim__define__module__export__(typeof fader != "undefined" ? fader : window.fader);

}).call(global, undefined, undefined, undefined, undefined, function defineExport(ex) { module.exports = ex; });
