;__browserify_shim_require__=require;(function browserifyShim(module, exports, require, define, browserify_shim__define__module__export__) {
(function(api, $, undefined){

	// setup private variables
	var data = {
			shifts: [],
			hasShifted: false,
			hasRetained: false,
			shiftColor: "",
			colors: core.data.userSettings.colors,
			duration: 250
		},

		/**
			private methods
		**/
		addEventHandlers = function(){

			// $(document).on("core_page_reveal_start", function(e){
			$(document).on("core_page_reveal_begin", function(e){

				if(api.pageHasColorShiftingSupport(core.getPage().pageID) && api.getHasShifted()){
					api.retainColors(0, e.pageData.pageID);
				}

			});

			$(document).bind("core_page_reveal_complete", function(){

				if(!api.pageHasColorShiftingSupport() && api.getHasShifted()){
					api.processUnshift(500);
				}

			});

			$(document).on("slideshow_active_image_updated", function(e) {

				api.processShift(e.activeImgObj.color, data.duration);

			});

		},

		getColorShiftingPageTypes = function(){
			return ["gallery", "home", "info", "contact"];
		},

		getNonColorShiftingPageTypes = function(){
			return ["testimonials", "calendar", "links"];
		},

		getShiftDefaults = function(settings){
			var defaults = {
				target: "",
				prop: "",
				shade: 0,
				reset: "",
				opacity: 1,
				resetOpacity: 1
			};
			return defaults;
		},

		shiftColors = function(color, duration){

			var shifts = data.shifts,
				numShifts = shifts.length,
				numShiftsDone = 0,
				thisShift,
				props,
				shadedColor,
				i,
				shiftDone = function() {

					numShiftsDone++;

					if (numShiftsDone === numShifts) {

						core.triggerCustomEvent({
							type: "color_shift_end"
						});

					}

				};

			setHasShifted(true);

			core.triggerCustomEvent({
				type: "color_shift_start"
			});

			for(i = 0; i < numShifts; i++){

				thisShift = shifts[i];
				props = {};

				shadedColor = getShadedColor(color, thisShift.shade, thisShift.opacity).toString();
				props[thisShift.prop] = shadedColor;

				thisShift.target = $(thisShift.target.selector); // refresh selector

				// leave { queue: false } to avoid conflicting with other animations (such as image transitions)
				$.when(thisShift.target.animate(props, { duration: duration, queue: false })).then(shiftDone);


				/* working
				thisShift.target.not(thisShift.exclude).queue("shiftQueue", function(){

					$(this).animate(props, { duration: duration, queue: false });

				}).dequeue("shiftQueue");*/

			}

			// setHasShifted(true); // remove this
		}

		resetShifts = function(duration){

			var shifts = data.shifts,
				numShifts = shifts.length,
				numShiftsDone = 0,
				thisShift,
				props,
				i,
				shiftDone = function() {

					numShiftsDone++;

					if (numShiftsDone === numShifts) {

						core.triggerCustomEvent({
							type: "color_unshift_end"
						});

					}

				};

			setHasShifted(false);

			core.triggerCustomEvent({
				type: "color_unshift_start"
			});

			for(i = 0; i < numShifts; i++) {

				thisShift = shifts[i];

				// This check may not be necessary, when would shift not have target?
				if (thisShift.target) { // shift has a target

					props = {};
					props[thisShift.prop] = getShadedColor(api.getDefaultColors()[thisShift.reset], thisShift.resetShade, thisShift.resetOpacity);
					thisShift.target = $(thisShift.target.selector); // refresh selector

					$.when(thisShift.target.animate(props)).then(shiftDone);

				}
			}

		},

		getShadedColor = function(base, shade, opacity){

			var shade = typeof shade !== "undefined" ? shade : 0,
				shadeVal = shade >= 0 ? '#000000' : '#ffffff',
				shadeAmt = shade >= 0 ? shade : (shade * -1),
				color = $.Color(base).transition(shadeVal, shadeAmt).toString(),
				opacity = typeof opacity !== "undefined" ? opacity : 1;

			if(opacity !== 1){ // adjust for opacity
				color = api.rgbToRgba(color, opacity);
				// color = color.replace("rgb", "rgba");
				// color = color.replace(")", "," + opacity + ")");
			}

			return color;
		},

		setHasShifted = function(hasShifted) {

			data.hasShifted = hasShifted;

		},

		setShiftColor = function(color){
			data.shiftColor = color;
		},

		setHasRetained = function(hasRetained){
			data.hasRetained = hasRetained;
		},

		getHasRetained = function(){
			return data.hasRetained;
		};

	/**
		return public API
	**/

	api.init = function(){
		if(template.configureShifting){
			template.configureShifting();
		}
		addEventHandlers();
	};

	api.rgbToRgba = function(rgb, alpha) {

		return (rgb.replace("rgb", "rgba")).replace(")", "," + alpha + ")");

	};

	// Accepts full rgb string, ex. "rgb(0,0,0)".
	api.rgbToHex = function(rgb) {

		rgb = rgb.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);

		return "#" +
			("0" + parseInt(rgb[1],10).toString(16)).slice(-2) +
			("0" + parseInt(rgb[2],10).toString(16)).slice(-2) +
			("0" + parseInt(rgb[3],10).toString(16)).slice(-2);

	};

	// Accepts 6 character hex string, with or without the pound.
	api.hexToRgb = function(h) {

		var r, g, b;

		h = h.charAt(0) === "#" ? h.substr(1) : h;

		r = parseInt(h.substring(0,2),16);

		g = parseInt(h.substring(2,4),16);

		b = parseInt(h.substring(4,6),16);

		return "rbg(" + r + "," + g + "," + b + ")";
	};

	api.getShiftColor = function(defaultKey, shade, defaultShade){
		var defaultKey = typeof defaultKey !== "undefined" ? defaultKey : undefined,
			shade = typeof shade !== "undefined" ? shade : 0,
			defaultShade = typeof defaultShade !== "undefined" ? defaultShade : 0,
			hasShiftSupport = api.pageHasColorShiftingSupport(),
			useShiftedColor = hasShiftSupport && api.getHasShifted(),
			useDefaultColor = !useShiftedColor,
			color = useShiftedColor ? getShadedColor(data.shiftColor, shade) : api.getColor(defaultKey, defaultShade);

		if(useDefaultColor && defaultShade){
			color = api.getColor(defaultKey, defaultShade);
		}

		return color;
	};

	api.getColor = function(colorKey, shade){
		var shade = typeof shade !== 'undefined' ? shade : 0,
			color = api.getDefaultColors()[colorKey.toLowerCase()];
		return getShadedColor(color, shade);
	};

	api.processShift = function(color, duration){

		var isColorDefined = color.length;

		if(isColorDefined){
			setShiftColor(color);
			shiftColors(color, duration);
			setHasRetained(false);
		}
		else {
			api.retainColors(duration);
		}

	};

	api.processUnshift = function(duration){

		resetShifts(duration);

	};

	api.pageHasColorShiftingSupport = function(pageId){
		var pageId = typeof pageId !== 'undefined' ? pageId : undefined;
		if(pageId){ // pageId only necessary to check page other than current page
			var pageData = core.getPage(pageId);
		}
		else{
			var pageData = core.getPage();
		}
		return $.inArray(pageData.type, getColorShiftingPageTypes()) >= 0;
	};

	api.addShift = function(settings){
		data.shifts.push($.extend(getShiftDefaults(), settings));
	};

	api.retainColors = function(duration, pageID){

		var pageHasShiftSupport = api.pageHasColorShiftingSupport(pageID);

		if(pageHasShiftSupport && api.getHasShifted()){
			shiftColors(api.getShiftColor(), duration);
			setHasRetained(true);
		}

	};

	api.getHasShifted = function(){
		return data.hasShifted;
	};

	api.getDefaultColors = function(){
		return data.colors;
	};

})(window.colorManager = window.colorManager || {}, jQuery);

; browserify_shim__define__module__export__(typeof colorManager != "undefined" ? colorManager : window.colorManager);

}).call(global, undefined, undefined, undefined, undefined, function defineExport(ex) { module.exports = ex; });
