'use strict';

var dom = require('form-widget-dom');
var $ = (typeof window !== "undefined" ? window.jQuery : typeof global !== "undefined" ? global.jQuery : null);
// Make a require() call for parsley to make sure it's included in the build.
// Parsley creates several global objects from one file, which is not supported
// by browserify-shim.
require('parsley');
var ParsleyValidator = (typeof window !== "undefined" ? window.ParsleyValidator : typeof global !== "undefined" ? global.ParsleyValidator : null);
var ParsleyUI = (typeof window !== "undefined" ? window.ParsleyUI : typeof global !== "undefined" ? global.ParsleyUI : null);
var notify = require('notify');
var emitter = require('emitter');
var fileupload = require('form-widget-fileupload');
var time = require('form-widget-time');
var option = require('form-widget-option');
var address = require('form-widget-address');
var fullname = require('form-widget-fullname');
var rating = require('form-widget-rating');
var familyfriends = require('form-widget-familyfriends');
var datepicker = require('form-widget-datepicker');
var settings = require('form-widget-settings');

var validClass = 'fb-validate-valid';
var errorClass = 'fb-validate-error';
var state = {
	parsleyFormInstance: null,
	submissionData: null
};

var parsleyOptions = {
	errorClass: errorClass,
	successClass: validClass,
	requiredMessage: 'This field is required.',
	// Next two props disable errors from being injected into the DOM.
	// Error messaging will be manually handled through use of elMsg.js
	errorsWrapper: '',
	errorsMessagesDisabled: true,
	priorityEnabled: false,
	excluded: 'input[type="button"], input[type="submit"], input[type="reset"], input[type="hidden"], [disabled], .fb-linked-hide :hidden, .fb-rating-compact input[type="radio"], .fb-rating-expanded select'
};

var getParsleyFieldInstance = function($element) {

	return $element.parsley();

};

var getErrorMsgs = function(parsleyFieldInstance) {

	return ParsleyUI.getErrorsMessages(parsleyFieldInstance);

};

var getMsgTarget = function($element) {

	var $nestedFieldContainer = dom.getNestedFieldContainer($element);
	var isNested = !!$nestedFieldContainer.length;


	if (isNested) {

		return $nestedFieldContainer.find('.fb-field-title label');

	} else if (rating.isElementRatingRadio($element)) {

		return $element.closest('tr').find(':first');

	} else {

		return dom.getFieldContainer($element).find('.fb-field-title label');

	}

};

var processErrors = function() {

	// Do a fresh search each time since fields can by added to the form
	// dynamically (familyfriends).0
	var $fieldContainers = $('.fb-field-container');

	$fieldContainers.each(function(i) {

		var $this = $fieldContainers.eq(i);

		if ($this.find('.'+errorClass).length) {

			$this.addClass(errorClass);

		} else {

			$this.removeClass(errorClass);

		}

	});

};

var flagAsError = function($element) {

	$element
		.removeClass(validClass)
		.addClass(errorClass);

};

var flagAsValid = function($element) {

	$element
		.removeClass(errorClass)
		.addClass(validClass);

};

var clearFlags = function($element) {

	$element.removeClass(errorClass + ' ' + validClass);

};

var showErrorMsg = function(parsleyFieldInstance) {

	var $element = parsleyFieldInstance.$element;
	var msg;

	if (!$element.hasClass(errorClass)) {

		return;

	}

	$element = getMsgTarget($element);
	msg = getErrorMsgs(parsleyFieldInstance)[0];

	// Only show message if its not already displayed.
	if ((!notify.isNotificationTarget($element) || notify.msg !== msg)) {

		notify.show({
			target: $element,
			msg: msg
		});

	} else {

		notify.scrollIntoView();

	}

};

var hideErrorMsg = function($element) {

	if (notify.isNotificationTarget(getMsgTarget($element))) {

		// An error message is currently displayed for this field.

		notify.hide();

	}

};

var addCustomValidators = function() {

	ParsleyValidator
		.addValidator('fileuploadselection', function () {

			// console.log( 'upload validation test', this );

			return fileupload.validateFileSelection();

		}, 3)
		.addMessage('en', 'fileuploadselection', 'This field is required.');

	ParsleyValidator
		.addValidator('fileuploadtype', function (value, requirement) {

			// console.log( 'upload validation test', this );

			return fileupload.validateFileType(requirement);

		}, 2)
		.addMessage('en', 'fileuploadtype', 'File type must be one of the following: %s');

	ParsleyValidator
		.addValidator('fileuploadsize', function (value, requirement) {

			// console.log( 'upload size test', value, requirement );

			return fileupload.validateFileSize(requirement);

		}, 1)
		.addMessage('en', 'fileuploadsize', 'File size cannot exceed %sMB.');

	ParsleyValidator
		.addValidator('dateformat', function (value) {

			// var exp = {
			// 	'mm/dd/yyyy': /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/,
			// 	'dd/mm/yyyy': /^(0[1-9]|1\d|2\d|3[01])\/(0[1-9]|1[0-2])\/(19|20)\d{2}$/
			// };

			return (/^\d{2}[\/]\d{2}[\/]\d{4}$/).test(value);

		}, 1)
		.addMessage('en', 'dateformat', 'Please use the following format: %s');

	ParsleyValidator
		.addValidator('validdate', function (value, requirement) {

			var isValidDate;

			try {

				isValidDate = !!$.datepicker.parseDate(requirement, value);

			} catch(e) {

				isValidDate = false;

			}

			return isValidDate;

		}, 2)
		.addMessage('en', 'validdate', 'Please enter a valid date.');

	ParsleyValidator
		.addValidator('timegroup', function (value, requirement) {

			var $this = $(requirement);
			var $fields = dom.getFieldInputs(dom.getFieldContainer($this));
			var valCnt = 0;
			var numFields = $fields.length;
			var noneSelected;
			var someSelected;
			var allSelected;

			$fields.each(function(i) {

				if ($fields.eq(i).val()) {

					valCnt++;

				}

			});

			noneSelected = valCnt === 0;
			someSelected = valCnt > 0 && valCnt < numFields;
			allSelected = valCnt === numFields;

			if (noneSelected || allSelected) {

				return true;

			} else if (someSelected) {

				// If this component has a value, mark as valid.
				return !!$this.val();

			}

		}, 1)
		.addMessage('en', 'timegroup', 'Please enter a complete time.');

};

var setupForm = function() {

	state.parsleyFormInstance = dom.fbForm.parsley(parsleyOptions);

};

var addEventHandlers = function() {

	dom.inputContainer.off('.fb-validate');
	dom.fbForm.off('.fb-validate');
	state.parsleyFormInstance.unsubscribe('parsley:field:error');
	state.parsleyFormInstance.unsubscribe('parsley:field:success');
	state.parsleyFormInstance.unsubscribe('parsley:form:validated');

	state.parsleyFormInstance.subscribe('parsley:field:error', function(parsleyFieldInstance) {

		var $element = parsleyFieldInstance.$element;

		// Parsley doesn't apply the error/valid classes until after this handler
		// runs. Manually swap the classes so we can update our UI accordingly.
		flagAsError($element);
		processErrors();
		showErrorMsg(parsleyFieldInstance);

	});

	state.parsleyFormInstance.subscribe('parsley:field:success', function(parsleyFieldInstance) {

		var $element = parsleyFieldInstance.$element;

		// Parsley doesn't apply the error/valid classes until after this handler
		// runs. Manually swap the classes so we can update our UI accordingly.
		flagAsValid($element);
		processErrors();

		// Time fields that have a multiple select UI are a bit unique in
		// that the field is composed of multiple inputs, but there is only
		// one title for the field as a whole. This is in contrast with
		// similar fields like address, full name, etc. that have titles
		// for each individual input. Therefore, we only want to hide a
		// notification on time fields if all of the selects are valid,
		// not just one.

		if (time.isCustom($element) && !time.validateCustom($element)) {

			return;

		}

		hideErrorMsg($element);

	});

	state.parsleyFormInstance.subscribe('parsley:form:validated', function(/*parsleyFormInstance*/) {

		// console.log( 'end form validation', parsleyFormInstance );

		processErrors();

	});

	dom.inputContainer.on('focus.fb-validate', '.fb-field-input', function() {

		showErrorMsg(getParsleyFieldInstance($(this)));

	});

	dom.fbForm.on('reset.fb-validate', function() {

		// Reset Parsley UI.
		state.parsleyFormInstance.reset();

		// Reset any jQuery UI select menus.
		emitter.emit('fbWidget-dropdown-reset', $('select'));

	});

	dom.fbForm.on('submit.fb-validate', function(e) {

		var jsonData = {
			fields: [],
			selectedOptions: []
		};

		var antiSpamFields = {};

		var $selectedOptions = $();

		e.preventDefault();

		dom.cols.each(function(i) {

			var $col = dom.cols.eq(i);
			var $input = dom.getFieldInputs($col);
			var fieldObj;

			if (!$input.length) {

				// This col has no user inputs, skip.
				return;

			}

			if (time.isTimeType($col)) {

				fieldObj = time.getVal($col);

			} else if (datepicker.isDateType($col)) {

				fieldObj = datepicker.getVal($col);

			} else if (option.isOptionType($col)) {

				fieldObj = option.getVal($col);

				if (fieldObj.value) {

					$selectedOptions = $selectedOptions.add(option.getSelectedOption($col));

				}

			} else if (address.isAddressType($col)) {

				fieldObj = address.getVal($col);

			} else if (fullname.isFullnameType($col)) {

				fieldObj = fullname.getVal($col);

			} else if (rating.isRatingType($col)) {

				fieldObj = rating.getVal($col);

			} else if (familyfriends.isFamilyfriendsType($col)) {

				fieldObj = familyfriends.getVal($col);

			} else if (fileupload.isFileuploadType($col)) {

				fieldObj = fileupload.getVal($col);

			} else {

				fieldObj = {
					name: dom.getFieldTitleText($col),
					value: $input.val()
				};

			}

			// if ($.isArray(fieldObj)) {

			// 	Array.prototype.push.apply(jsonData.fields, fieldObj);

			// } else {

				fieldObj.id = dom.getFieldId($col);

				jsonData.fields.push(fieldObj);

			// }

		});

		// Parse the selected options so server can verify they are still
		// available.

		// console.log( 'options', $selectedOptions );

		$selectedOptions.each(function(i) {

			var $this = $selectedOptions.eq(i);
			var optionCoords = option.getOptionCoords($this);

			jsonData.selectedOptions.push(optionCoords);

		});

		// Add anti-spam fields to data.
		dom.getAntiSpamFields().each(function() {

			var $this = $(this);

			antiSpamFields[$this.attr('name')] = $this.val();

		});

		// console.log( JSON.stringify(jsonData, null, '\t') );

		state.submissionData = jsonData;

		dom.triggerPublicFormEvent({
			type: 'fbWidget-form-submit',
			data: jsonData
		});

		dom.submitBtn.attr('disabled', '');

		$.ajax({
			type: 'post',
			cache: false,
			url: settings.submitUrl,
			data: $.extend({}, {data: JSON.stringify(jsonData)}, antiSpamFields)
		}).done(function(data/*, textStatus, jqXHR*/) {

			console.log( 'submission success', arguments );

			var $target;

			data = JSON.parse(data);

			if (data.status) {

				// Inject conversion script.
				dom.fbForm.append(data.conversionScript);

				if (data.redirect) {

					// The customer wants to redirect to a specific url upon
					// successful submission.
					window.location.href = data.redirect;

				} else {

					dom.triggerPublicFormEvent({
						type: 'fbWidget-form-submit-success',
						response: data
					});

				}

			} else {

				$target = dom.submitBtn;

				// A selected option is no longer available.
				if (data.target) {

					$target = $(data.target);
					option.disable($target);

				}

				notify.show({
					target: getMsgTarget($target),
					msg: data.msg
				});

				dom.document.one('click', function() {

					notify.hide();

				});

			}

		}).fail(function(/*jqXHR, textStatus, errorThrown*/) {

			// console.log( 'submission error', arguments );

			notify.show({
				target: dom.submitBtn,
				msg: 'The form cannot be submitted at this time. Please check your network connection and try again.'
			});

		}).always(function() {

			dom.submitBtn.removeAttr('disabled');

		});

	});

	// Handle dynamic adding of frindsfamily fields.
	emitter.on('fbWidget-familyfriends-field-added', function(data) {

		dom.getFieldInputs(data.$row).each(function() {

			var $this = $(this);

			// Make clones optional and set them up with the correct options.
			$this
				// .removeAttr('data-parsley-required')
				.parsley(parsleyOptions);

			clearFlags($this);

		});

		processErrors();

	});

	// Handle dynamic removing of frindsfamily fields.
	emitter.on('fbWidget-familyfriends-field-remove', function(data) {

		dom.getFieldInputs(data.$row).each(function() {

			// Hide notification if on an input that is about to be removed.

			hideErrorMsg($(this));

		});

	});

	emitter.on('fbWidget-linked-field-hidden', function($target) {

		// Hide error notification for a linked field if it is hidden.

		hideErrorMsg($target.find('.fb-field-container'));

		notify.position();

	});

	emitter.on('fbWidget-linked-field-shown', function() {

		// Position error message in case the newly visible field caused
		// it to move out of place.

		notify.position();

	});

	emitter.on('fbWidget-rating-layout-adjusted', function() {

		if (notify.isNotificationActive() && rating.isElementRatingField(notify.target)) {

			// A notification is currently displayed for a rating dropdown or
			// radio.

			notify.hide();

		}

		state.parsleyFormInstance.validate('rating');

	});

	emitter.on('fbWidget-layout-adjusted', function() {

		// Update the position of any notification while layout is adjusting.

		notify.position();

	});

	emitter.on('fbWidget-destroy', function() {

		notify.hide();

	});

};

module.exports = {

	init: function() {

		addCustomValidators();
		setupForm();
		addEventHandlers();

	},

	get submissionData() {

		return state.submissionData;

	}

};