
; require("/Users/edull/pbiz/html/client/apps/portfolio/src/js/slideshow.js");
;__browserify_shim_require__=require;(function browserifyShim(module, define, require) {
/* global core: false */
/* global slideshow: false */
/* global _: false */

(function(api, $, undefined) {

	var settings = {},

		state = {},

		dom = {},

		saveSettings = function() {

			settings = slideshow.settings;

		},

		setupDom = function() {

			dom = {
				document: core.dom.getElement({key: "document"}),
				imageTarget: settings.imageTarget
			};

		},

		cachePluginDomElements = function() {

			dom.fullDimensionContainer = dom.imageTarget.find(".mCSB_container");

			dom.scrollTools = dom.imageTarget.find(".mCSB_scrollTools");

			dom.dragger = dom.scrollTools.find(".mCSB_dragger");

		},

		addEventHandlers = function() {

			dom.document.on("slideshow_slider_slide_to.control_slider", function(e) {

				// Fire callbacks on non-touch devices so we can fix the issue where
				// the content is not slid to the correct value (task #5525). We can't
				// fire callbacks for touch devices b/c we have no way of knowing if
				// the user's finger is still on the dragger handle. See note in
				// onSliderEnd().
				var doCallbacks;

				// Some templates may need to force a resize event to update the
				// layout. Make sure the slider has been created before attempting
				// to reference it.
				if (!dom.imageTarget.mCustomScrollbar) {

					return;

				}

				if (slideshow.isTouch()) {

					doCallbacks = false;

				} else {

					if (e.changedBy === "auto" || e.changedBy === "resize") {

						// Don't want onSliderStart to trigger, which would kill autoplay.
						doCallbacks = false;

					} else {

						doCallbacks = true;

					}

				}

				state.lastPosition = e.position;

				if (e.changedBy === "resize") {

					dom.imageTarget.mCustomScrollbar("update");

				}

				// Stop any current sliding.
				dom.imageTarget.mCustomScrollbar("stop");

				dom.imageTarget.mCustomScrollbar("scrollTo", e.position, {callbacks: doCallbacks});

			});

			dom.imageTarget.on("click.control_slider", ".mCSB_draggerContainer", function(e) {

				var $target = $(e.target);

				if ($target.hasClass("mCSB_draggerContainer") || $target.hasClass("mCSB_draggerRail") || $target.hasClass("slider-progress-bar")) {

					core.triggerCustomEvent({
						type: "slideshow_slider_rail_clicked",
						// Cross browser offsetX.
						offsetX: e.pageX - dom.scrollTools.offset().left,
						// Cross browser offsetY.
						offsetY: e.pageY - dom.scrollTools.offset().top,
						fullContentWidth: getFullContentWidth()
					});

				}

			});

			if (slideshow.isTouch()) {

				// The plugin never receives these events when the overlay div is
				// present, so we must manually trigger them.
				dom.imageTarget.on("touchstart.control_slider touchmove.control_slider", function(e) {

					dom.fullDimensionContainer.trigger(e);

				});

				// Touch handling for dragging the bar.
				dom.document.on("touchend.control_slider", ".mCSB_dragger", function() {

					processDraggerReleased();

				});

			} else {

				// Allow user to click dragger bar and move mouse to anywhere in the window.
				// IE10 with "Standards" mode only fires MSPointerUp when releasing dragger.
				// IE11 doesn't support "MS" prefixed pointer events, hence the pointerup event.
				dom.document.on(window.navigator.msPointerEnabled ? "MSPointerUp.control_slider pointerup.control_slider" : "mouseup.control_slider", function() {

					// _.log("mouseup");

					if (isUserDragging()) {

						processDraggerReleased();

					}

				});

			}

		},

		processDraggerReleased = function() {

			// _.log("processing dragger released");

			core.triggerCustomEvent({
				type: "slideshow_slider_dragger_released",
				draggerTop: dom.dragger.position().top,
				draggerLeft: dom.dragger.position().left,
				draggerWidth: dom.dragger.width(),
				railWidth: dom.scrollTools.width(),
				fullContentWidth: getFullContentWidth()
			});

		},

		isUserDragging = function() {

			return dom.dragger.hasClass("mCSB_dragger_onDrag");

		},

		getFullContentWidth = function() {

			return dom.fullDimensionContainer.outerWidth();

		},

		getPluginJs = function() {

			$.ajax({
				type: "GET",
				url: "/portfolio/vendor/slider/jquery.mCustomScrollbar.concat.min.js",
				dataType: "script",
				cache: true,
				success: function() {

					slideshow.triggerModuleReady("controlSlider");

				}
			});

		},

		onSliderStart = function() {

			// This is to kill autoplay when dragging handle.
			core.triggerCustomEvent({
				type: "slideshow_control_fired",
				controlType: "slider_start"
			});

		},

		onSliderEnd = function() {

			// _.log("onSliderEnd()...");

			if (slideshow.isTouch()) {

				// Should be checking if user is dragging here, but we have no way of
				// knowing this b/c the plugin blocks touchstart and doesn't apply
				// the drag class internally for touch devices. If that gets resolved
				// then we can do that check and allow callbacks to be fired when we
				// "scrollTo" in the handler for slideshow_slider_slide_to.
				processDraggerReleased();

			} else {

				// _.log("lastPosition="+state.lastPosition);
				// _.log("current position="+parseInt(dom.fullDimensionContainer.css("left"), 10));

				// Only verify the scrolled position if the user has released the dragger handle.
				if (!isUserDragging()) {

					// Fix for task #5525.
					if (Math.abs(parseInt(dom.fullDimensionContainer.css("left"), 10)) !== state.lastPosition) {

						// _.log("Slider Error!!!! Adjust me!!!");

						// Don't scroll past the last image.
						if (state.lastPosition + dom.imageTarget.width() < dom.fullDimensionContainer.width()) {

							dom.fullDimensionContainer.css({left: -state.lastPosition});

						}

					}

				}

			}

		},

		// Any element with class of "nowrap" will be taken out of the plugin
		// wrapper and placed as a child of the image target.
		unwrapElements = function() {

			dom.imageTarget
				.find(".nowrap")
					.detach()
					.appendTo(dom.imageTarget);

		},

		injectProgressBar = function() {

			// Not using :before b/c it caused some issues with hovering and
			// clicking the rail.
			dom.dragger.prepend("<div class='slider-progress-bar' />");

		},

		destroy = function(duration) {

			return $.Deferred(function(dfd) {

				dom.document.off(".control_slider");

				window.mcs = undefined;

				// Wait to destroy the slider until the content has faded out,
				// otherwise the display will jerk.
				setTimeout(function() {

					dom.imageTarget.mCustomScrollbar("destroy");

					dfd.resolve();

				}, duration);

			}).promise();

		};

	api.initPlugin = function() {

		dom.imageTarget.mCustomScrollbar({
			horizontalScroll: true,
			mouseWheel: false,
			autoDraggerLength: settings.autoDraggerLength,
			// Don't allow touch scrolling if thumbs and slider are not displayed.
			// contentTouchScroll: settings.showThumbs ? true : false,
			contentTouchScroll: settings.showSlider ? true : false,
			advanced: {
				// Block plugin from handling resize.
				updateOnBrowserResize: false,

				// Updates width of plugin container when resizing.
				autoExpandHorizontalScroll: true
			},
			callbacks: {
				onScrollStart: onSliderStart,
				onScroll: onSliderEnd
			}
		});

		unwrapElements();

		cachePluginDomElements();

		if (settings.showSliderProgressBar) {

			injectProgressBar();

		}

	};

	api.init = function() {

		saveSettings();

		setupDom();

		addEventHandlers();

		slideshow.addCleanupItem(destroy);

		getPluginJs();

	};

}(window.slideshow.controlSlider = window.slideshow.controlSlider || {}, jQuery));
}).call(global, module, undefined, undefined);
