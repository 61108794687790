
; require("/Users/edull/pbiz/html/client/apps/portfolio/src/js/core.util.js");
;__browserify_shim_require__=require;(function browserifyShim(module, define, require) {
/*
    Things to clean up:

        1. template.dom.thumbs
        2. menuBackground/menuHover (should be injected before core.dom.init() is called, that way we can reference the cached element in template.configureShifting().)
        3. #icons and #footer, why is this here, looks like template specific wrapper
*/

(function(api, $, undefined) {

    // Private variables and methods.

    var util = core.util,

            elements = {},

            addCoreElements = function() {

                var dom = core.dom;

                /*
                 * Misc. elements.
                 */
                dom.addElement({
                    key: "window",
                    $el: $(window)
                });

                dom.addElement({
                    key: "html",
                    $el: $("html")
                });

                dom.addElement({
                    key: "document",
                    $el: $(document)
                });

                dom.addElement({
                    key: "body",
                    $el: $("body")
                });

                dom.addElement({
                    key: "metaKeywords",
                    $el: $("meta[name='keywords']")
                });

                dom.addElement({
                    key: "metaDesc",
                    $el: $("meta[name='description']")
                });

                dom.addElement({
                    key: "spinner",
                    $el: $("#spinner")
                });

                dom.addElement({
                    key: "contentWrapper",
                    $el: $("#content")
                });

                dom.addElement({
                    key: "viewer",
                    $el: $("#viewer")
                });

                dom.addElement({
                    key: "pBizBrand",
                    $el: $("#pbizbrand")
                });


                /*
                 * Logo elements.
                 */
                dom.addElement({
                    key: "logoWrapper",
                    $el: $("#logo")
                });

                dom.addElement({
                    key: "logoContentWrapper",
                    $el: $("#logoInner")
                });

                dom.addElement({
                    key: "logoImage",
                    $el: elements["logoContentWrapper"].find("img")
                });

                dom.addElement({
                    key: "logoTextWrapper",
                    $el: $("#logoText")
                });


                /*
                 * Social icon elements.
                 */
                dom.addElement({
                    key: "icons",
                    $el: $("#icons")
                });

                dom.addElement({
                    key: "socialIconsWrapper",
                    $el: $("#socialIcons")
                });

                dom.addElement({
                    key: "socialIconsWrapperDesignerColor",
                    $el: $("#socialIcons.designer.color")
                });

                dom.addElement({
                    key: "socialIconsWrapperDesignerGreyscale",
                    $el: $("#socialIcons.designer.greyscale")
                });

                dom.addElement({
                    key: "socialIconLinks",
                    $el: elements["socialIconsWrapper"].find("a")
                });

                dom.addElement({
                    key: "socialIconImages",
                    $el: elements["socialIconsWrapper"].find("a img")
                });

                dom.addElement({
                    key: "socialIconLinksDesignerColor",
                    $el: $("#socialIcons.designer.color").find("a")
                });

                dom.addElement({
                    key: "socialIconLinksDesignerGreyscale",
                    $el: $("#socialIcons.designer.greyscale").find("a")
                });

                dom.addElement({
                    key: "socialIconLinksStandardColor",
                    $el: $("#socialIcons.standard.color").find("a")
                });

                dom.addElement({
                    key: "socialIconLinksStandardGreyscale",
                    $el: $("#socialIcons.standard.greyscale").find("a")
                });


                /*
                 * Footer elements.
                 */
                dom.addElement({
                    key: "footer",
                    $el: $("#footer")
                });

                dom.addElement({
                    key: "footerText",
                    $el: $("#footerText")
                });


                /*
                 * Menu elements.
                 */
                dom.addElement({
                    key: "menuWrapper",
                    $el: $("#menu") // The outer menu <div> wrapper.
                });

                dom.addElement({
                    key: "topMenu",
                    $el: elements["menuWrapper"].find(".topMenu") // The main menu <ul>.
                });

                dom.addElement({
                    key: "menuLis",
                    $el: elements["topMenu"].find(".topLi") // The main menu <li>s.
                });

                dom.addElement({
                    key: "menuLinks",
                    $el: elements["menuWrapper"].find(".topLink") // The main menu <a>s.
                });

                dom.addElement({
                    key: "menuLinkEndpoint",
                    $el: elements["menuWrapper"].find(".topLink:not(.ddmenu, .elink, .selected-parent)") // The main menu <a>s that load a new page.
                });

                dom.addElement({
                    key: "selectedMenuLink",
                    $el: elements["menuWrapper"].find(".selected-parent") // The selected main menu <a>.
                });

                dom.addElement({
                    key: "activeMenuLinks",
                    $el: elements["menuWrapper"].find(".hover, .selected-parent") // The main menu <a>s that are hovered or selected.
                });

                dom.addElement({
                    key: "menuLinksNotSelected",
                    $el: elements["menuWrapper"].find(".topLink:not(.selected-parent)") // The main menu <a>s NOT selected.
                });

                dom.addElement({
                    key: "menuLinksNotActive",
                    $el: elements["menuWrapper"].find(".topLink:not(.hover, .selected-parent)") // The main menu <a>s NOT selected or hovered.
                });

                dom.addElement({
                    key: "subWrappers",
                    $el: elements["menuWrapper"].find(".sub-wrapper") // All outer sub menu <div> wrappers.
                });

                dom.addElement({
                    key: "subMenus",
                    $el: elements["menuWrapper"].find(".subMenu") // All <ul> sub menus.
                });

                dom.addElement({
                    key: "subMenuLis",
                    $el: elements["subMenus"].find(".subLi") // All sub menu <li>s.
                });

                dom.addElement({
                    key: "subMenuLinks",
                    $el: elements["subWrappers"].find(".subLink") // All sub menu <a>s.
                });

                dom.addElement({
                    key: "subMenuLinkEndpoint",
                    $el: elements["subWrappers"].find(".subLink:not(.selected-child, .elink)") // The sub menu <a>s that load a new page.
                });

                dom.addElement({
                    key: "selectedSubMenuLink",
                    $el: elements["subWrappers"].find(".selected-child") // The selected sub menu <a>.
                });

                dom.addElement({
                    key: "activeSubMenuLinks",
                    $el: elements["subWrappers"].find(".hover, .selected-child") // The sub menu <a>s that are hovered or selected.
                });

                dom.addElement({
                    key: "subMenuLinksNotSelected",
                    $el: elements["subWrappers"].find(".subLink:not(.selected-child)") // All sub menu <a>s NOT selected.
                });

                dom.addElement({
                    key: "subMenuLinksNotActive",
                    $el: elements["subWrappers"].find(".subLink:not(.hover, .selected-child)") // All sub menu <a>s NOT selected or hovered.
                });

                dom.addElement({
                    key: "menuBackground",
                    $el: $("#menuBackground")
                });

                dom.addElement({
                    key: "menuHover",
                    $el: $("#menuHover")
                });


                /*
                 * Slideshow elements.
                 */
                dom.addElement({
                    key: "thumbOuterWrapper",
                    $el: $("#thumbContainer")
                });

                dom.addElement({
                    key: "thumbInnerWrapper",
                    $el: $("#thumbs")
                });

                dom.addElement({
                    key: "thumbs",
                    $el: elements["thumbInnerWrapper"].find(".thumb") // All thumbs.
                });

                dom.addElement({
                    key: "selectedThumb",
                    $el: elements["thumbInnerWrapper"].find(".thumb.current") // The selected thumb.
                });

                dom.addElement({
                    key: "activeThumbs",
                    $el: elements["thumbInnerWrapper"].find(".thumb.current, .thumb:hover") // The hovered or selected thumb.
                });

                dom.addElement({
                    key: "thumbsNotSelected",
                    $el: elements["thumbInnerWrapper"].find(".thumb:not(.current)") // All thumbs NOT selected.
                });

                dom.addElement({
                    key: "thumbsNotHovered",
                    $el: elements["thumbInnerWrapper"].find(".thumb:not(:hover)") // All thumbs NOT selected.
                });

                dom.addElement({
                    key: "thumbsNotActive",
                    $el: elements["thumbInnerWrapper"].find(".thumb:not(.current).thumb:not(:hover)") // All thumbs NOT selected or hovered.
                });

                dom.addElement({
                    key: "activeImageWrapper",
                    $el: $("#viewer .large-wrapper.active-image")
                });

                dom.addElement({
                    key: "imageWrappers",
                    $el: elements.viewer.find(".large-wrapper")
                });

            };

    // Public properties and methods.

    api.init = function() {

        this.body = $("body");
        this.spinner = $('#spinner');
        this.content = $('#content');
        this.viewer = $('#viewer');
        this.logo = $('#logo');
        this.icons = $('#icons');
        this.footer = $('#footer');
        this.footerText = $('#footerText');
        this.menu = $('#menu');
        this.subwrappers = this.menu.find(".sub-wrapper");
        this.submenus = this.menu.find(".subMenu");
        this.menuLinks = $('.topLink');
        this.submenuLinks = this.menu.find('.subLink');
        this.pbizbrand = $("#pbizbrand");
        this.thumbs = $(".thumb");
        this.thumbContainer = $("#thumbContainer");
        this.menuBackground = $("<div id='menuBackground' />");
        this.menuHover = $("<div id='menuHover' />");

        // New design.
        addCoreElements();

    };

    api.addElement = function(domObj) {

        if (!elements[domObj.key]) {

            elements[domObj.key] = domObj.$el;

        }   else {

            console.log("WARNING: NOT ADDED. An element with the key " + domObj.key + " already exists. To update an element use core.dom.updateElement()");

        }

    };

    api.updateElement = function(domObj) {

        if (elements[domObj.key]) {

            elements[domObj.key] = domObj.$el;

        }   else {

            console.log("WARNING: NOT UPDATED. No element with the key " + domObj.key + " already exists. To add a new element use core.dom.addElement()");

        }

    };

    api.getElement = function() {

        var defaultProps = {
                refresh: false,
                key: ""
            },
            props = util.mergeDefaultProperties(defaultProps, arguments);

        if (elements[props.key]) {

            return props.refresh ? $(elements[props.key].selector) : elements[props.key];

        } else {

            console.log("WARNING: No element with the key " + props.key + " exists. To add a new element use core.dom.addElement()");

        }

    };


    /*
     * Menu helpers.
     */
    api.doesMenuItemHaveDropDown = function(props) {

        return props.$target.hasClass("ddmenu");

    };

    api.isMenuItemSelected = function(props) {

        return props.$target.attr("data-selected") === "true";

    };

    api.isMenuItemExpanded = function(props) {

        return props.$target.attr("data-expanded") === "true";

    };

    /*
     * Get the links for the targeted sub menu.
     */
    api.getSubMenuLinks = function(props) {

        return props.$target.find(".subLink");

    };


    /*
     * Logo helpers.
     */
    api.doesLogoTextExist = function() {

        var $logoTextWrapper,
            argumentsArray = util.argumentsObjectToArray(arguments);

        // Push a new object into the array, forcing the key to always be "logoTextWrapper".
        argumentsArray.push({key: "logoTextWrapper"});

        $logoTextWrapper = api.getElement.apply(api, argumentsArray);

        return {
            result: !!$logoTextWrapper.length && !$logoTextWrapper.is(":empty"),
            $element: $logoTextWrapper
        }

    };

    api.doesLogoImageExist = function() {

        var $logoImage,
            argumentsArray = util.argumentsObjectToArray(arguments);

        // Push a new object into the array, forcing the key to always be "logoImage".
        argumentsArray.push({key: "logoImage"});

        $logoImage = api.getElement.apply(api, argumentsArray);

        return {
            result: !!$logoImage.length,
            $element: $logoImage
        };

    };

    api.doesLogoContentExist = function() {

        var argumentsArray = util.argumentsObjectToArray(arguments),
            logoImageTest = api.doesLogoImageExist.apply(api, argumentsArray),
            logoTextTest = api.doesLogoTextExist.apply(api, argumentsArray);

        return {
            result: logoImageTest.result || logoTextTest.result,
            $element: logoImageTest.result ? logoImageTest.$element : logoTextTest.$element // Return the logo image or text wrapper.
        }

    };

    api.getLogoAlignment = function() {

        var $logoContentWrapper = api.getElement({key: "logoContentWrapper"}),
            alignment = "";

        if ($logoContentWrapper.hasClass("logo-center")) {

            alignment = "center";

        } else if ($logoContentWrapper.hasClass("logo-left")) {

            alignment = "left";

        } else if ($logoContentWrapper.hasClass("logo-right")) {

            alignment = "right";

        }

        return alignment;

    };


    /*
     * Social icon helpers.
     */
    api.doSocialIconsExist = function() {

        var $socialIconsWrapper,
            argumentsArray = util.argumentsObjectToArray(arguments);

        // Push a new object into the array, forcing the key to always be "socialIconsWrapper".
        argumentsArray.push({key: "socialIconsWrapper"});

        $socialIconsWrapper = api.getElement.apply(api, argumentsArray);

        return {
            // Using is(":empty") here can cause problems if there is whitespace in the div.
            result: !!$socialIconsWrapper.find("img").length,
            $element: $socialIconsWrapper
        };

    };

    api.isIconStyleDesigner = function() {

        return core.data.userSettings.iconStyle === "designer";

    };

    api.isIconStyleStandard = function() {

        return core.data.userSettings.iconStyle === "standard";

    };

})(window.core.dom = window.core.dom || {}, jQuery);
}).call(global, module, undefined, undefined);
