'use strict';

var dom = require('form-widget-dom');
var settings = require('form-widget-settings');
var $ = (typeof window !== "undefined" ? window.jQuery : typeof global !== "undefined" ? global.jQuery : null);
var emitter = require('emitter');

var widgetize = function($target) {

	$target = $target || $('select');

	// Turn target select boxes into jQuery UI widget.
	$target.selectmenu({
		width: 'auto'
	});

};

var refresh = function($target) {

	$target.selectmenu('refresh');

};

var addEventHandlers = function() {

	dom.fbForm.off('.fb-selectmenu');

	dom.fbForm.on('selectmenuchange.fb-selectmenu', 'select', function() {

		$(this).change();

	});

	dom.fbForm.on('focus.fb-selectmenu', '.ui-selectmenu-button', function() {

		$(this).prev().focus();

	});

	dom.fbForm.on('blur.fb-selectmenu', '.ui-selectmenu-button', function() {

		$(this).prev().blur();

	});

	emitter.on('fbWidget-address-states-populated', function($target) {

		refresh($target);

	});

	emitter.on('fbWidget-rating-radio-clicked', function($target) {

		refresh($target);

	});

	emitter.on('fbWidget-dropdown-reset', function($target) {

		refresh($target);

	});

	emitter.on('fbWidget-familyfriends-field-added', function(data) {

		// Remove cloned jQuery UI select box.
		data.$row
			.find('.ui-selectmenu-button, .ui-selectmenu-menu')
				.remove();

		// Setup new jQuery UI select box.
		widgetize(data.$select);

	});

};

module.exports = {

	init: function() {

		if (settings.useJqueryUISelect) {

			addEventHandlers();

			widgetize();

		}

	}

};