
; require("/Users/edull/pbiz/html/client/modules/vendor/jquery-jplayer/jquery.jplayer.js");
;__browserify_shim_require__=require;(function browserifyShim(module, exports, require, define, browserify_shim__define__module__export__) {
window.music = {

	data: {
		next: 0,
		pages: {},
		currentPage: null,
		initiated: false
	},

	dom: {},

	setupDomElements: function() {
		music.dom.player = jQuery("#player");
		music.dom.onOff = jQuery("#musicOnOff a");
		music.dom.eqBars = jQuery("#musicEq span");
	},

	init: function(page, playlist, autoPlay, isTouchDevice, playMusicOnLoad) {

		music.setupDomElements();

		playMusicOnLoad = typeof playMusicOnLoad !== "undefined" ? playMusicOnLoad : true;

		music.data.paused = isTouchDevice || (autoPlay ? false : true) || jQuery.cookie("musicPaused") === "true" || !playMusicOnLoad;

		jQuery("#musicPlayerWrapper").css("visibility", "visible").show();

		if (music.data.pages[page] === undefined) {
			music.data.pages[page] = playlist;
		} else {

			// if it's same page, don't reset
			if (music.data.currentPage === page) {
				return;
			}

		}

		music.data.next = 0;
		music.data.currentPage = page;

		// if player has already been initiated, send a true
		// argument to play so that it will load the correct mp3
		if (music.data.initiated) {

			music.dom.player.jPlayer("setMedia", { mp3: music.data.pages[music.data.currentPage][music.data.next] });

			if (!music.data.paused) {

				music.play();

			} else {

				music.deflateBars();

			}

		} else {

			music.dom.player.jPlayer({
				ready: function() {

					jQuery(this).jPlayer("setMedia", {
						mp3: music.data.pages[music.data.currentPage][music.data.next]
					});

					if (!music.data.paused) {
						music.play();
					}

				},
				play: function() {

					music.data.paused = false;
					music.dom.onOff.html("music off");

					music.dom.eqBars.each(function() {
						music.eq(jQuery(this));
					});

				},
				loop: true,
				swfPath: "/portfolio/js/"
			});

			music.dom.player.bind(jQuery.jPlayer.event.ended, function(e) {

				if (music.data.next + 1 === music.data.pages[music.data.currentPage].length) {
					music.data.next = 0;
				} else {
					music.data.next++;
				}

				music.dom.player.jPlayer("setMedia", {
					mp3: music.data.pages[music.data.currentPage][music.data.next]
				}).jPlayer("play");

			});

			music.dom.onOff.click(function() {
				return music.toggle();
			});

			jQuery("#musicEq").click(function() {
				return music.toggle();
			});

		}

		music.data.initiated = true;

	},

	eq: function(bar) {

		var height = Math.random() * 4;
		height += 5;
		var duration = height * 50;

		if (!music.data.paused) {

			bar.animate({
				height: height
			}, duration, function() {
				music.eq(jQuery(this));
			});

		}

	},

	play: function() {

		music.data.paused = false;
		jQuery.cookie("musicPaused", "false");
		music.dom.player.jPlayer("play");

	},

	pause: function() {

		music.data.paused = true;
		music.dom.onOff.html("music on");
		music.dom.player.jPlayer("pause");
		jQuery.cookie("musicPaused", "true");

		music.deflateBars();

	},

	deflateBars: function() {

		music.dom.eqBars.each(function() {

			jQuery(this)
				.stop()
				.animate({ height: 1 }, 300);

		});

	},

	isPaused: function(){
		return music.data.paused == true;
	},

	toggle: function() {

		if (!music.data.paused) {
			music.pause();
		} else {
			music.play();
		}
		return false;

	}

};

(function(jQuery){
	jQuery(document).on("click", ".elink[data-disable-music=1],#socialIcons a,.disable_music_1 .itemList", function(){
		music.pause();
	});
}(jQuery));
; browserify_shim__define__module__export__(typeof music != "undefined" ? music : window.music);

}).call(global, undefined, undefined, undefined, undefined, function defineExport(ex) { module.exports = ex; });
