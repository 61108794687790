
; require("/Users/edull/pbiz/html/client/apps/portfolio/src/js/slideshow.js");
;__browserify_shim_require__=require;(function browserifyShim(module, define, require) {
/* global core: false */
/* global slideshow: false */

(function(api, $, undefined){

	var state = {},

		dom = {},

		settings = {},

		saveSettings = function() {

			settings = slideshow.settings;

		},

		setupDom = function() {

			dom = {
				window: core.dom.getElement({ key: "window" }),
				document: core.dom.getElement({ key: "document" }),
				content: core.dom.getElement({key: "contentWrapper"}),
				controls: $("#controls"),
				overlay: $("<div id='overlay' />"),
				play: $("#play"),
				pause: $("#pause"),
				next: $("#next"),
				prev: $("#prev")
			};

		},

		setupInitialState = function() {

			state = {
				controlTimer: null
			};

			addControlCssClasses();

			swapPlayPause(false, settings.autoplay);

		},

		injectMarkup = function(){

			dom.overlay
				.css({bottom: settings.reflectionHeight})
				.appendTo(dom.content);

			dom.controls.appendTo(dom.overlay);

		},

		addControlCssClasses = function(){

			var $controls = getControlElements();

			// add control theme and color classes
			$controls.parents("#controls").addClass(settings.controlThemeClass);
			$controls.addClass(slideshow.getColorThemeClass(settings.mainControlColorKey));

		},

		addEventHandlers = function(){

			dom.document.on("mousedown.control_buttons", ".touch #controls", function() {

				initControlTimer();

			});

			dom.document.on("mousemove.control_buttons", "#overlay, #controls", function(e) {

				processMouseMovementOverSlideshow(e);

			});

			dom.document.one("slideshow_active_image_updated.control_buttons", function() {

				initControlTimer();

			});

			dom.overlay.on("mouseleave.control_buttons", function() {

				initControlTimer();

			});

			dom.controls.find("div").on("click.control_buttons", function(e) {

				var controlType = $(this).attr("id");

				e.stopPropagation();
				e.preventDefault();

				if (interceptControlEvent(controlType)) {

					return;

				}

				switch (controlType) {

					case "pause":
					case "play":
						swapPlayPause(true, !slideshow.autoplay.isPlaying());
						break;

					case "prev":
					case "next":
						swapPlayPause(false, false);
						break;

				}

				core.triggerCustomEvent({
					type: "slideshow_control_fired",
					"controlType": controlType
				});

			});

			dom.document.on("slideshow_control_fired.control_buttons", function(e){

				if (e.controlType === "thumbs" || e.controlType === "slider") {

					swapPlayPause(false, false);

				}

			});

		},

		interceptControlEvent = function(controlType){

			return ((controlType === "prev" || controlType === "next") && slideshow.isTransitionInProgress()) || slideshow.getImgCount() <= 1;

		},

		swapPlayPause = function(showButtonAfterSwapping, isPlaying){

			if(isPlaying){
				dom.play.css({ display: "none" });
				dom.pause.css({ display: "block", opacity: showButtonAfterSwapping ? 1 : 0 });
			}
			else{
				dom.pause.css({ display: "none" });
				dom.play.css({ display: "block", opacity: showButtonAfterSwapping ? 1 : 0 });
			}

		},

		initControlTimer = function(delay){

			delay = typeof delay !== "undefined" ? delay : 1500;

			state.controlTimer = window.setTimeout(function() {

				core.triggerCustomEvent({
					type: "slideshow_enter_idle_state"
				});

				hideControls();

			}, delay);

		},

		clearControlTimer = function(){
			window.clearTimeout(state.controlTimer);
			state.controlTimer = null;
		},

		processMouseMovementOverSlideshow = function(e){
			var x = e.pageX,
				y = e.pageY,
				$content = dom.content,
				contentTop = $content.offset().top,
				contentLeft = $content.offset().left,
				mouseX = x - contentLeft,
				mouseY = y - contentTop,
				contentWidth = $content.width(),
				contentHeight = $content.height(),
				hotspotDims = getHotspotDimensions(contentWidth, contentHeight),
				hotspotResult = isMouseWithinHotspot(mouseX, mouseY, hotspotDims),
				isMouseOverHotspot = hotspotResult.isCollision,
				showControl = isMouseOverHotspot && isControlHidden(hotspotResult.label);

			if(showControl){
				showControls(hotspotResult.label);
			}

			if(isMouseOverHotspot){
				if(state.controlTimer){
					clearControlTimer();
				}
			}

			else{
				if(!state.controlTimer){
					initControlTimer();
				}
			}
		},

		isControlHidden = function(control){
			return parseInt(dom[control].css("opacity"), 10) === 0;
		},

		isValWithinRange = function(val, start, stop){
			return val > start && val < stop;
		},

		isMouseWithinHotspot = function(mouseX, mouseY, dims){

			var hit = false,
				hotspotLabels = ["prev", "play", "next"],
				label,
				i;

			for(i = 0; i < dims.length; i++){

				if( isValWithinRange(mouseX, dims[i].startX, dims[i].endX) && isValWithinRange(mouseY, dims[i].startY, dims[i].endY) ){
					hit = true;
					label = hotspotLabels[i];
					break;
				}

			}

			return { isCollision: hit, label: label };

		},

		getHotspotDimensions = function(contentWidth, contentHeight){

			var hotspotWidth = contentWidth / 3,
				hotspotHeight = contentHeight / 3,
				hotspots = [];

			hotspots.push({
				startX: 0,
				startY: hotspotHeight,
				endX: hotspotWidth,
				endY: hotspotHeight * 2
			});

			hotspots.push({
				startX: hotspotWidth,
				startY: hotspotHeight,
				endX: hotspotWidth * 2,
				endY: hotspotHeight * 2
			});

			hotspots.push({
				startX: hotspotWidth * 2,
				startY: hotspotHeight,
				endX: hotspotWidth * 3,
				endY: hotspotHeight * 2
			});

			return hotspots;

		},

		hideControls = function(){

			dom.controls.find("div").animate({ opacity: 0 });

		},

		showControls = function(hotspotList){

			var hotspotSelector = getControlHotspotSelector(hotspotList);

			revealControl(hotspotSelector);

		},

		getControlHotspotSelector = function(hotspotList){ //  this method takes a hotspot label, such as "prev,next"

			var hotspotArr = hotspotList.split(",");

			for(var i = 0; i < hotspotArr.length; i++){
				if(hotspotArr[i] === "play"){
					hotspotArr[i] = !slideshow.autoplay.isPaused() ? "pause" : "play";
				}

				hotspotArr[i] = "#" + hotspotArr[i];
			}

			return hotspotArr.join(",");
		},

		areControlsAnimating = function(){

			return dom.controls.find("div").is(":animated");

		},

		revealControl = function(hotspotSelector){

			if(!areControlsAnimating()){

				dom.controls.find("div").not(hotspotSelector).animate({ opacity: 0 }, 100, function(){

					if(parseInt(dom.controls.find(hotspotSelector).css("opacity"), 10) === 0){

						dom.controls.find(hotspotSelector).animate({ opacity: 1 }, 100);

					}

				});

			}

		},

		getControlElements = function() {

			return dom.controls.find("div"); // used to be $("#play,#pause,#next,#prev")

		},

		destroy = function() {

			dom.document.off(".control_buttons");

		};

	api.moveContainer = function(target) {

		dom.overlay
			.detach()
			.prependTo(target);

	};

	api.init = function(){

		setupDom();

		saveSettings();

		setupInitialState();

		injectMarkup();

		addEventHandlers();

		showControls("prev,next");

		slideshow.addCleanupItem(destroy);

		slideshow.triggerModuleReady("controlButtons");

	};

}(window.slideshow.controlButtons = window.slideshow.controlButtons || {}, jQuery));
}).call(global, module, undefined, undefined);
