'use strict';

var dom = require('form-widget-dom');
var $ = (typeof window !== "undefined" ? window.jQuery : typeof global !== "undefined" ? global.jQuery : null);
var emitter = require('emitter');
var settings = require('form-widget-settings');

var populateStates = function($select, states) {

	$select.html(states);

	emitter.emit('fbWidget-address-states-populated', $select);

};

var getStates = function() {

	var $this = $(this);
	var $stateSelect = $this.closest('.fb-nested-col').find('select[id$="state"]');
	var country = $this.val();

	$.ajax({
		type: 'get',
		cache: true,
		url: settings.statesUrl + '&c=' + country,
		dataType: 'html'
	}).done(function(response) {

		populateStates($stateSelect, response);

	});

};

var addEventHandlers = function() {

	dom.fbForm.off('.fb-address');

	dom.fbForm.on('change.fb-address', '.fb-address-type [id$="country"]', getStates);

};

module.exports = {

	init: function() {

		addEventHandlers();

	},

	isAddressType: function($col) {

		return $col.hasClass('fb-address-type');

	},

	getVal: function($col) {

		var $els = dom.getFieldInputs($col);
		var name = dom.getFieldTitleText($col);
		var vals = [];

		$els.each(function(i) {

			var $el = $els.eq(i);

			vals.push({
				name: dom.getNestedFieldTitleText($el),
				value: $el.val()
			});

		});

		return {
			name: name,
			value: vals
		};

	}

};