;__browserify_shim_require__=require;(function browserifyShim(module, exports, require, define, browserify_shim__define__module__export__) {

window.scroller = {

	dom: {},
	data: {},

	init: function(contact, fullWidth) {

		// add destroy method to call when unloading page
		core.addUnload(scroller.destroy);

		// data based on options passed in
		scroller.data.contact = contact;
		scroller.data.fullWidth = false;

		if (fullWidth) {
			scroller.data.fullWidth = true;
		}

		// other data used by this module
		scroller.data.position = 'left';
		scroller.data.size = 9;
		scroller.data.isOverPanel = false;
		scroller.data.isDrag = undefined;
    	scroller.data.barHeight = undefined;
		scroller.data.minBarHeight = 15;
		scroller.data.wheelStep = 30;
		scroller.data.posCss = (scroller.data.position === 'right') ? { right: '0' } : { left: '0' };
		scroller.data.contentAlignment = (core.getPage().align === 'left') ? 'right' : 'left';

		scroller.data.fillWidth = ($.inArray(core.getPage().type, ['links','testimonials']) >= 0) || scroller.data.fullWidth;

		if (!scroller.data.fillWidth) {

			// _.log("fill width of page");
			scroller.data.initialWidth = core.dom.content.innerWidth() / 2;
			scroller.data.alignCss = (scroller.data.contentAlignment === 'right') ? { right: 0 } : { left: 0 };

		} else {

			// _.log("don't fill width");
			scroller.data.initialWidth = '100%';
			scroller.data.alignCss = {};

		}

		// DOM elements used by this module
		scroller.dom.positioner = $('#textContentPositioner');
		scroller.dom.content = $('#textContent');
		scroller.dom.wrapper = $('#scrollDiv');
		scroller.dom.heading = $('h1');
		scroller.dom.formWrapper = $('#formWrapper');

		if(template.data.layout.dynamicScrollerMargin){
			scroller.dom.positioner.css({ margin: $(window).width() * template.data.layout.dynamicScrollerMargin });
		}

		scroller.dom.wrapper.css({ opacity: 0 });
		scroller.setupPadding();
		// scroller.data.positionerPadding = parseInt(scroller.dom.positioner.css('padding-left'), 10) + parseInt(scroller.dom.positioner.css("padding-right"), 10) ;

		// UK WILL WIN IT ALL!
		//scroller.data.positionerMargin = parseInt(scroller.dom.positioner.css("margin-left")) * 2;
		scroller.data.positionerMarginWidth = parseInt(scroller.dom.positioner.css("margin-left")) * 2;
		scroller.data.positionerMarginHeight = parseInt(scroller.dom.positioner.css("margin-top")) * 2;

		scroller.data.initialHeight = scroller.dom.heading.length > 0 ? (core.dom.content.height() - scroller.dom.heading.outerHeight(true) - 20) : (core.dom.content.height() - 20);

		// true if form page has images
		if (scroller.data.contact) {

			// scroller.dom.contactForm = $('#contact');
			scroller.dom.contactForm = scroller.dom.positioner.find('form');
			scroller.data.initialHeight -= scroller.dom.contactForm.outerHeight(true)+20;

		}

		scroller.data.contentPadding = (scroller.data.position === 'right') ? parseInt(scroller.dom.content.css('padding-right')) : parseInt(scroller.dom.content.css('padding-left'));

		scroller.dom.positioner.css({
			position: 'absolute',
			// top: scroller.dom.heading.height() + 10,
			/* UK RULES! width: scroller.data.initialWidth - scroller.data.positionerPadding.x - scroller.data.positionerMargin,
			height: core.dom.content.height() - scroller.data.positionerPadding.y - scroller.data.positionerMargin*/
			width: scroller.data.initialWidth - scroller.data.positionerPadding.x - scroller.data.positionerMarginWidth,
			height: core.dom.content.height() - scroller.data.positionerPadding.y - scroller.data.positionerMarginHeight
		}).css(scroller.data.alignCss);

		scroller.dom.wrapper.css({
			/* UK RULES! width: scroller.data.initialWidth - scroller.data.positionerPadding.x - scroller.data.positionerMargin*/
			width: scroller.data.initialWidth - scroller.data.positionerPadding.x - scroller.data.positionerMarginWidth
		});

		scroller.dom.content.css({
			/*w UK RULES! idth: scroller.data.initialWidth - scroller.data.contentPadding - scroller.data.positionerPadding.x - scroller.data.positionerMargin*/
			width: scroller.data.initialWidth - scroller.data.contentPadding - scroller.data.positionerPadding.x - scroller.data.positionerMarginWidth
		});

	    scroller.dom.rail = $('<div id="scrollRail">').css({
			width: 9,
			height: '100%',
			position: 'absolute',
			top: 0
	    }).css(scroller.data.posCss);

	    //create scrollbar
		scroller.dom.bar = $('<div id="scrollBar">').css({
			width: scroller.data.size,
			position: 'absolute',
			top: 0,
			display: 'block',
			zIndex: 99
	    }).css(scroller.data.posCss);

	    //append to parent div
	    scroller.dom.wrapper.append(scroller.dom.bar).append(scroller.dom.rail);

	    //make it draggable
		scroller.dom.bar.draggable({
			axis: 'y',
			containment: 'parent',
			start: function() {
				scroller.data.isDrag = true;
			},
			stop: function() {
				scroller.data.isDrag = false;
			},
			drag: function(e) {
	        	scroller.scrollContent(0, $(this).position().top, false);
	        }
	    });

	    scroller.dom.content.hover(function() {
			scroller.data.isOverPanel = true;
	    }, function() {
	        scroller.data.isOverPanel = false;
	    });

    	//attach scroll events
	    scroller.attachWheel();

	    scroller.touchScroll();

		scroller.data.isInitialLoad = true;

		// $(document).bind('core_page_reveal_complete', function(){
			if(scroller.data.isInitialLoad){
				scroller.data.isInitialLoad = false;
				scroller.resize();
			}
			/*if(scroller.dom.positioner){
				scroller.dom.positioner.animate({ opacity: 1 }, 500);
			}*/
		// });

		$(window).bind('resize.scroller', scroller.resize);

		$(document).on("mousedown", "#scrollRail", function(e){ // allow clicking in rail to jump scroll position

			var $scrollRail = $(this),
				$scrollBar = scroller.dom.bar,
				eligibleMaxScroll = $scrollRail.height() - $scrollBar.height(),
				offset = $scrollRail.offset(),
				x = e.pageX - offset.left,
				y = Math.round(e.pageY - offset.top);

			if(y > eligibleMaxScroll){
				y = eligibleMaxScroll;
			}

			scroller.performScroll(y);
			scroller.moveScrollbar(y);
		});

		// Many templates have page type specific padding and margin rules
		// for the textContentPositioner. Once the page hooks are added,
		// update the scroller layout.
		$(document).on("core_css_hooks_added.scroller", function() {

			scroller.refresh();

		});

    },

    setupPadding: function() {

    	scroller.data.positionerPadding = {top: 0, right: 0, bottom: 0, left: 0};
		scroller.data.positionerPadding.top = parseInt(scroller.dom.positioner.css("padding-top"), 10);
		scroller.data.positionerPadding.right = parseInt(scroller.dom.positioner.css("padding-right"), 10);
		scroller.data.positionerPadding.bottom = parseInt(scroller.dom.positioner.css("padding-bottom"), 10);
		scroller.data.positionerPadding.left = parseInt(scroller.dom.positioner.css("padding-left"), 10);
		scroller.data.positionerPadding.x = scroller.data.positionerPadding.left + scroller.data.positionerPadding.right;
		scroller.data.positionerPadding.y = scroller.data.positionerPadding.top + scroller.data.positionerPadding.bottom;

    },

	onWheel: function(e) {

        //use mouse wheel only when mouse is over
    	if (!scroller.data.isOverPanel) {
    		return;
    	}

		var delta = 0;
        var e = e || window.event;

        if (e.wheelDelta) {
        	delta = -e.wheelDelta / 120;
        }

        if (e.detail) {
        	delta = e.detail / 3;
        }

        //scroll content
		scroller.scrollContent(0, delta, true);

        //stop window scroll
        if (e.preventDefault) {
        	e.preventDefault();
        }

        e.returnValue = false;

    },

	scrollContent: function(x, y, isWheel) {

        var delta = y;

        if (isWheel) {

            //move bar with mouse wheel
            delta = scroller.dom.bar.position().top + y * scroller.data.wheelStep;

            //move bar, make sure it doesn't go out
            delta = Math.max(delta, 0);
            delta = Math.min(delta, scroller.dom.content.outerHeight() - scroller.dom.bar.outerHeight());

            //scroll the scrollbar
            // scroller.dom.bar.css({ top: delta + 'px' });
			scroller.moveScrollbar(delta);

        }

		scroller.performScroll(delta, scroller.dom.bar.position().top);

    },

	moveScrollbar: function(delta){
		scroller.dom.bar.css({ top: delta + 'px' });
	},

	performScroll: function(delta){

		// _.log("delta: " + delta, top);

		//calculate actual scroll amount
        var percentScroll = parseInt(delta) / (scroller.dom.content.outerHeight() - scroller.dom.bar.outerHeight());
        delta = percentScroll * (scroller.dom.content[0].scrollHeight - scroller.dom.content.outerHeight());

        //scroll content
        scroller.dom.content.scrollTop(delta);

	},

    attachWheel: function() {

		scroller.dom.content[0].addEventListener('DOMMouseScroll', scroller.onWheel, false);
        scroller.dom.content[0].addEventListener('mousewheel', scroller.onWheel, false);

    },

    getBarHeight: function() {

        //calculate scrollbar height and make sure it is not too small

        if (scroller.dom.content[0].scrollHeight > 0) {

			scroller.data.barHeight = Math.max(
				scroller.data.minBarHeight,
				(scroller.dom.content.outerHeight() / scroller.dom.content[0].scrollHeight) * scroller.dom.content.outerHeight()
			);

		} else {
			scroller.data.barHeight = Math.max(scroller.data.minBarHeight, scroller.dom.content.height());
		}



		scroller.dom.bar.css('height', scroller.data.barHeight);

	},

	refresh: function() {

		scroller.setupPadding();
		scroller.resize();

	},

	resize: function() {

		if(template.data.layout.dynamicScrollerMargin){

			var scrollerMargin = $(window).width() * template.data.layout.dynamicScrollerMargin,
					scrollerMargin = scrollerMargin < template.data.layout.minScrollerMargin ? template.data.layout.minScrollerMargin : scrollerMargin;

			scroller.dom.positioner.css({ margin: scrollerMargin });

			//scroller.data.positionerMargin = parseInt(scroller.dom.positioner.css("margin-left"), 10) * 2;

			// UK OWNS DUKE AND CAROLINA
			scroller.data.positionerMarginWidth = parseInt(scroller.dom.positioner.css("margin-left"), 10) + parseInt(scroller.dom.positioner.css("margin-right"), 10);
			scroller.data.positionerMarginHeight = parseInt(scroller.dom.positioner.css("margin-top"), 10) + parseInt(scroller.dom.positioner.css("margin-bottom"), 10);
			// scroller.data.positionerMarginWidth = parseInt(scroller.dom.positioner.css("margin-left"), 10) * 2;
			// scroller.data.positionerMarginHeight = parseInt(scroller.dom.positioner.css("margin-top"), 10) * 2;
		}

		if (!scroller.data.fillWidth) {
			var w = core.dom.content.innerWidth() / 2;
		} else {
			var w = core.dom.content.width();
		}

    	scroller.dom.positioner.css({
			/* UK RULES! width: w - scroller.data.positionerPadding.x - scroller.data.positionerMargin,
			height: core.dom.content.height() - scroller.data.positionerPadding.y - scroller.data.positionerMargin*/
			width: w - scroller.data.positionerPadding.x - scroller.data.positionerMarginWidth,
			height: core.dom.content.height() - scroller.data.positionerPadding.y - scroller.data.positionerMarginHeight
		});

		var h = scroller.dom.heading.length > 0 ? (scroller.dom.positioner.height() - scroller.dom.heading.outerHeight(true) - 20) : (scroller.dom.positioner.height() - 20);

		if (scroller.data.contact) {
			h -= scroller.dom.contactForm.find('.jspPane').outerHeight(true)+20;
		}

		if (h < 60) {
			h = 60;
		}

		// If there is no actual text content, don't give the wrappers a height.
		if ($.trim(scroller.dom.content.html()) === "") {

			h = 0;

		}

		scroller.dom.heading.css({ opacity: 1 });

  //   	scroller.dom.positioner.css({
		// 	//  UK RULES! width: w - scroller.data.positionerPadding.x - scroller.data.positionerMargin,
		// 	// height: core.dom.content.height() - scroller.data.positionerPadding.y - scroller.data.positionerMargin
		// 	width: w - scroller.data.positionerPadding.x - scroller.data.positionerMarginWidth,
		// 	height: core.dom.content.height() - scroller.data.positionerPadding.y - scroller.data.positionerMarginHeight
		// });

    	scroller.dom.wrapper.css({
			/* UK RULES! width: w - scroller.data.positionerPadding.x - scroller.data.positionerMargin,*/
			width: w - scroller.data.positionerPadding.x - scroller.data.positionerMarginWidth,
			height: h,
			opacity: 1
		});

		scroller.dom.content.css({
			/* UK RULES! width: w - scroller.data.contentPadding - scroller.data.positionerPadding.x - scroller.data.positionerMargin,*/
			width: w - scroller.data.contentPadding - scroller.data.positionerPadding.x - scroller.data.positionerMarginWidth,
			height: h
		});

		var positionerTotalWidth = scroller.dom.positioner.outerWidth(true);
		var positionerLeftSpace = parseInt(scroller.dom.positioner.css('paddingLeft'), 10) + parseInt(scroller.dom.positioner.css('marginLeft'), 10);


		scroller.dom.formWrapper.css({
			width: positionerTotalWidth - positionerLeftSpace,
			height: scroller.dom.positioner.height(),
			paddingTop: scroller.dom.positioner.css('paddingTop'),
			paddingBottom: scroller.dom.positioner.css('paddingBottom'),
			marginTop: scroller.dom.positioner.css('marginTop'),
			marginRight: positionerLeftSpace,
			marginBottom: scroller.dom.positioner.css('marginBottom'),
			marginLeft: 0
		});

        scroller.getBarHeight();

        //show only when required
        if (scroller.data.barHeight >= scroller.dom.content.outerHeight()) {

            scroller.dom.bar.hide();
            scroller.dom.rail.hide();
            scroller.dom.content.css('padding', 0);

			if (scroller.dom.heading.length) {
	            scroller.dom.heading.removeClass('h1-scroll-margin');
	        }

            return;

        }

        if (scroller.dom.heading.length) {
            scroller.dom.heading.addClass('h1-scroll-margin');
        }

		if (scroller.data.position === 'right') {
			scroller.dom.content.css('padding-right', scroller.data.contentPadding);
		} else {
			scroller.dom.content.css('padding-left', scroller.data.contentPadding);
		}

        scroller.dom.bar.show();
        scroller.dom.rail.show();

    },

    /* This function makes a div scrollable with android and iphone */

	isTouchDevice: function() {
		/* Added Android 3.0 honeycomb detection because touchscroll.js breaks
			the built in div scrolling of android 3.0 mobile safari browser */
		if((navigator.userAgent.match(/android 3/i)) ||
			(navigator.userAgent.match(/honeycomb/i)))
			return false;
		try{
			document.createEvent("TouchEvent");
			return true;
		}catch(e){
			return false;
		}
	},

	touchScroll: function() {

		var id = 'textContent';

		if(scroller.isTouchDevice()){ //if touch events exist...

			var el=document.getElementById(id);
			var scrollStartPosY=0;
			var scrollStartPosX=0;

			document.getElementById(id).addEventListener("touchstart", function(event) {
				scrollStartPosY=this.scrollTop+event.touches[0].pageY;
				scrollStartPosX=this.scrollLeft+event.touches[0].pageX;
				//event.preventDefault(); // Keep this remarked so you can click on buttons and links in the div
			},false);

			document.getElementById(id).addEventListener("touchmove", function(event) {
				// These if statements allow the full page to scroll (not just the div) if they are
				// at the top of the div scroll or the bottom of the div scroll
				// The -5 and +5 below are in case they are trying to scroll the page sideways
				// but their finger moves a few pixels down or up.  The event.preventDefault() function
				// will not be called in that case so that the whole page can scroll.
				if ((this.scrollTop < this.scrollHeight-this.offsetHeight &&
					this.scrollTop+event.touches[0].pageY < scrollStartPosY-5) ||
					(this.scrollTop != 0 && this.scrollTop+event.touches[0].pageY > scrollStartPosY+5))

						event.preventDefault();
				if ((this.scrollLeft < this.scrollWidth-this.offsetWidth &&
					this.scrollLeft+event.touches[0].pageX < scrollStartPosX-5) ||
					(this.scrollLeft != 0 && this.scrollLeft+event.touches[0].pageX > scrollStartPosX+5))

						event.preventDefault();

				this.scrollTop=scrollStartPosY-event.touches[0].pageY;
				this.scrollLeft=scrollStartPosX-event.touches[0].pageX;



				var railHeight = $("#scrollRail").height(),
					barHeight = $("#scrollBar").height(),
					availableRailHeight = railHeight - barHeight,
					contentHeight = $("#textContent").height(),
					origHeight = $("#textContent")[0].scrollHeight,
					scrollTop = $("#textContent").scrollTop(),
					multiplier = railHeight / origHeight,
					offset = Math.round(scrollTop * multiplier);

					/*$("#log1").text("offset: " + offset);
					$("#log2").text("availableRailHeight: " + availableRailHeight);
					$("#log3").text("barHeight: " + barHeight);
					$("#log4").text("origHeight: " + origHeight);*/

				scroller.moveScrollbar(this.scrollTop * multiplier);

			},false);
		}
	},

    destroy: function() {

		window.clearTimeout(scroller.data.loadPause);

    	$(window).unbind('resize.scroller');
    	$(document).off(".scroller");

    	scroller.dom.content[0].removeEventListener('DOMMouseScroll', scroller.onWheel, false);
        scroller.dom.content[0].removeEventListener('mousewheel', scroller.onWheel, false);

    	scroller.dom.bar.draggable('destroy');

    	scroller.dom.bar.remove();
    	scroller.dom.rail.remove();

		delete scroller.dom.bar;
		delete scroller.dom.rail;
		delete scroller.dom.content;
		delete scroller.dom.positioner;
		delete scroller.dom.wrapper;
		delete scroller.dom.heading;

		if (scroller.data.contact) {
			delete scroller.dom.contactForm;
		}

		scroller.dom = {};
		scroller.data = {};

    }

};

; browserify_shim__define__module__export__(typeof scroller != "undefined" ? scroller : window.scroller);

}).call(global, undefined, undefined, undefined, undefined, function defineExport(ex) { module.exports = ex; });
