
; require("/Users/edull/pbiz/html/client/apps/portfolio/src/js/slideshow.js");
;__browserify_shim_require__=require;(function browserifyShim(module, define, require) {
/* global core: false */
/* global slideshow: false */
/* global template: false */

(function(api, $, undefined) {

	var state = {},

		dom = {},

		settings = {},

		saveSettings = function() {

			settings = slideshow.settings;

		},

		setupDom = function() {

			dom = {
				document: core.dom.getElement({key: "document"})
			};

		},

		setupInitialState = function() {

			state = {
				selectedThumb: 0,
				maxThumb: slideshow.getImgCount() - 1,
				displayedThumbSet: 0,
				maxThumbSet: 0,
				thumbSetLoaded: [],
				syncThumbSetToThumb: true
			};

			state.maxThumbSet = getThumbSetIdByThumb(state.maxThumb);

		},

		injectMarkup = function() {

			var thumbSetId,
				$thumbSetWrapper,
				thumbId,
				$thumbWrapper,
				thumbsPerSet = settings.thumbsPerSet;

			dom.markupTarget = template.galleryLoad();

			dom.markupTarget.addClass(settings.controlThemeClass + " " + slideshow.getColorThemeClass(settings.thumbControlColorKey) + " " + settings.thumbOrientation);

			// Previous arrow.
			dom.thumbSetPrev = $("<div id='thumbSetPrev' class='thumb-control'></div>").appendTo(dom.markupTarget);

			dom.thumbs = $("<div id='thumbs'></div>");

			// Create a wrapper for each thumb set.
			for (thumbSetId = 0; thumbSetId <= state.maxThumbSet; thumbSetId++) {

				$thumbSetWrapper = $("<div class='thumb-set'></div>");

				// Create a wrapper for each thumb.
				for (thumbId = thumbSetId * thumbsPerSet; thumbId < (thumbSetId + 1) * thumbsPerSet; thumbId++) {

					if (thumbId > state.maxThumb) {

						break;

					}

					$thumbWrapper = $("<div class='thumb' data-index='" + thumbId + "'></div>");

					/*if (settings.thumbType === "image") {

						$thumbWrapper.append("<img src='/portfolio/images/placeholder_1px.png'>");

					}*/

					if (settings.thumbNumbers) {

						$thumbWrapper
							.css({"display": "table"})
							.append("<div class='thumbNum'>" + (thumbId + 1) + "</div>");

					}

					if (settings.thumbOverlay) {

						$thumbWrapper.append("<div class='thumbOverlay'></div>");

					}

					if (settings.thumbImageTips) {

						$thumbWrapper.append("<div class='tip-wrapper'><img class='tip' src='/portfolio/images/placeholder_1px.png'></div>");

					}

					$thumbSetWrapper.append($thumbWrapper);

				}

				dom.thumbs.append($thumbSetWrapper);

			}

			dom.thumbs.appendTo(dom.markupTarget);

			// Next arrow.
			dom.thumbSetNext = $("<div id='thumbSetNext' class='thumb-control'></div>").appendTo(dom.markupTarget);

		},

		addEventHandlers = function() {

			dom.document.on("slideshow_active_image_updated.control_thumbs", function(e) {

				goToThumb(e.activeImgObj.index, e.changedBy);

			});

			dom.document.on("slideshow_control_fired.control_thumbs", function(e) {

				if (e.controlType === "play") {

					state.syncThumbSetToThumb = true;

				}

			});

			// Go to previous thumb set.
			dom.thumbSetPrev.on("click.control_thumbs", function(e) {

				e.preventDefault();
				e.stopPropagation();

				if (slideshow.isTransitionInProgress()) {

					return;

				}

				if (!isFirstThumbSetDisplayed()) {

					goToThumbSet(state.displayedThumbSet - 1);

				}

			});

			// Go to next thumb set.
			dom.thumbSetNext.on("click.control_thumbs", function(e) {

				e.preventDefault();
				e.stopPropagation();

				if (slideshow.isTransitionInProgress()) {

					return;

				}

				if (!isLastThumbSetDisplayed()) {

					goToThumbSet(state.displayedThumbSet + 1);

				}

			});

			dom.thumbs.on("click.control_thumbs", ".thumb", function(e) {

				var $thumb = $(this),
					thumbId = parseInt($thumb.attr("data-index"), 10);

				e.preventDefault();
				e.stopPropagation();

				if (isThumbSelected(thumbId) || slideshow.isTransitionInProgress()) {

					return;

				}

				state.syncThumbSetToThumb = true;

				core.triggerCustomEvent({
					type: "slideshow_control_fired",
					controlType: "thumbs",
					prevIdx: state.selectedThumb,
					activeIdx: thumbId
				});

			});

			if (settings.thumbImageTips) {

				dom.thumbs.on("mouseover.control_thumbs mouseout.control_thumbs", ".thumb", function(e) {

					var $thumbDiv = $(this),
						isMouseOver = e.type === "mouseover",
						isCurrent = $thumbDiv.is(".current"),
						visibility = isMouseOver ? "visible" : "hidden",
						$tip = $thumbDiv.find(".tip-wrapper");

					// only show/hide the tip if we're not on the current thumb, UNLESS we are mousing out, in which case remove the tip regardless
					if (!isCurrent || !isMouseOver) {

						$tip.css({ "visibility": visibility });

						// Allow templates to animate appearance of thumb image.
						core.triggerCustomEvent({
							type: "slideshow_tip_toggled",
							"visibility": visibility,
							"$tip": $tip
						});

					}

				});

			}

		},

		isThumbInDisplayedThumbSet = function(id) {

			return getThumbSetIdByThumb(id) === state.displayedThumbSet;

		},

		isThumbSelected = function(id) {

			return id === state.selectedThumb;

		},

		isFirstThumbSetDisplayed = function() {

			return state.displayedThumbSet === 0;

		},

		isLastThumbSetDisplayed = function() {

			return state.displayedThumbSet === state.maxThumbSet;

		},

		getThumbSetIdByThumb = function(id) {

			return Math.floor(id / settings.thumbsPerSet);

		},

		getThumbSet = function(id) {

			return dom.thumbs.find(".thumb-set").eq(id);

		},

		getThumbSets = function() {

			return dom.thumbs.find(".thumb-set");

		},

		getImageTargetsByThumbSet = function(id) {

			var target = settings.thumbType === "image" ? ".thumb" : "img";

			return getThumbSet(id).find(target);

		},

		getThumbsByThumbSet = function(id) {

			return getThumbSet(id).find(".thumb");

		},

		getThumb = function(id) {

			var thumbSetId = getThumbSetIdByThumb(id),
				// The thumb ID relative to its thumb set.
				relativeId = id - settings.thumbsPerSet * thumbSetId;

			return getThumbsByThumbSet(thumbSetId).eq(relativeId);

		},

		updateSelectedThumbClass = function(oldId, newId) {

			getThumb(oldId).removeClass("current");
			getThumb(newId).addClass("current");

		},

		goToThumb = function(id, changedBy) {

			if (changedBy === "user") {

				state.syncThumbSetToThumb = true;

			}

			updateSelectedThumb(id, changedBy);

			if ((state.syncThumbSetToThumb && !isThumbInDisplayedThumbSet(state.selectedThumb)) || changedBy === "startup") {

				updateDisplayedThumbSet(getThumbSetIdByThumb(state.selectedThumb), changedBy);

			}

		},

		goToThumbSet = function(id) {

			state.syncThumbSetToThumb = false;

			updateDisplayedThumbSet(id, "user");

		},

		updateSelectedThumb = function(id, changedBy) {

			var oldThumb = state.selectedThumb;

			if (id < 0) {

				id = state.maxThumb;

			} else if (id > state.maxThumb) {

				id = 0;

			}

			state.selectedThumb = id;

			updateSelectedThumbClass(oldThumb, state.selectedThumb);

			core.triggerCustomEvent({
				"type": "slideshow_thumb_updated",
				"oldThumb": oldThumb,
				// jQuery object for backwards compatibility.
				"currentThumb": getThumb(state.selectedThumb),
				"changedBy": changedBy
			});

		},

		updateDisplayedThumbSet = function(id, changedBy) {

			if (id < 0) {

				id = state.maxThumbSet;

			} else if (id > state.maxThumbSet) {

				id = 0;

			}

			state.displayedThumbSet = id;

			updateArrows();

			loadThumbs();

			displayThumbSet();

			$(document).trigger("slideshow_thumbs_loaded");

		},

		displayThumbSet = function() {

			getThumbSets()
				.hide()
				.removeClass("active");

			getThumbSet(state.displayedThumbSet)
				.show()
				.addClass("active");

		},

		updateArrows = function() {

			if (state.maxThumbSet === 0) {

				hideArrows();
				return;

			}

			switch (state.displayedThumbSet) {

				case 0:
					disableArrow("thumbSetPrev");
					enableArrow("thumbSetNext");
					break;

				case state.maxThumbSet:
					enableArrow("thumbSetPrev");
					disableArrow("thumbSetNext");
					break;

				default:
					enableArrow("thumbSetPrev");
					enableArrow("thumbSetNext");
					break;

			}

		},

		hideArrows = function() {

			dom.thumbSetPrev.hide();
			dom.thumbSetNext.hide();

		},

		enableArrow = function(which) {

			dom[which].removeClass("inactive");

		},

		disableArrow = function(which) {

			dom[which].addClass("inactive");

		},

		loadThumbs = function() {

			var thumbSetId,
				endThumbSetId = state.displayedThumbSet + 1,
				thumbId,
				$imgTargets,
				targetIdx = 0,
				numTargets,
				endThumbId,
				imgSrc;

			endThumbSetId = endThumbSetId > state.maxThumbSet ? state.maxThumbSet : endThumbSetId;

			for (thumbSetId = state.displayedThumbSet; thumbSetId <= endThumbSetId; thumbSetId++) {

				// Has this thumb set been loaded already?
				if (!state.thumbSetLoaded[thumbSetId]) {

					state.thumbSetLoaded[thumbSetId] = true;

					// Get all images or the thumb containers in the thumb set.
					$imgTargets = getImageTargetsByThumbSet(thumbSetId);

					targetIdx = 0;

					numTargets = $imgTargets.length;

					endThumbId = thumbSetId * settings.thumbsPerSet + numTargets - 1;

					// Load each image.
					for (thumbId = thumbSetId * settings.thumbsPerSet; thumbId <= endThumbId; thumbId++) {

						imgSrc = slideshow.getImgUrl(thumbId, settings.thumbSize);

						if (settings.thumbType === "image") {

							$($imgTargets[targetIdx++]).css("background", "url(" + imgSrc + ") 50% 50% no-repeat");

						} else {

							$imgTargets[targetIdx++].src = imgSrc;

						}

					}

				}

			}

		},

		destroy = function() {

			dom.document.off(".control_thumbs");

			template.galleryUnload();

			dom.markupTarget.remove();

			delete template.dom.thumbs;

		};

	api.init = function() {

		saveSettings();

		setupDom();

		setupInitialState();

		injectMarkup();

		updateArrows();

		loadThumbs();

		displayThumbSet();

		addEventHandlers();

		slideshow.addCleanupItem(destroy);

		slideshow.triggerModuleReady("controlThumbs");

	};

}(window.slideshow.controlThumbs = window.slideshow.controlThumbs || {}, jQuery));
}).call(global, module, undefined, undefined);
