
; require("/Users/edull/pbiz/html/client/apps/portfolio/src/js/slideshow.js");
;__browserify_shim_require__=require;(function browserifyShim(module, define, require) {
/* global core: false */
/* global slideshow: false */

(function(api, $, undefined) {

	var settings = {},

		dom = {},

		setupDom = function() {

			dom = {
				window: core.dom.getElement({key: "window"}),
				document: core.dom.getElement({key: "document"}),
				imageTarget: settings.imageTarget
			};

		},

		setupModules = function() {

			var modules = ["captions", "autoplay", "images"];

			if (settings.showThumbs) {

				modules.push("controlThumbs");

			}

			if (settings.showButtons) {

				modules.push("controlButtons");

			}

			slideshow.loadModules(modules);

		},

		saveSettings = function() {

			settings = slideshow.settings;

		},

		addEventHandlers = function() {

			dom.document.on("slideshow_images_scaled.standard", function() {

				positionWrappers();

			});

			dom.document.on("slideshow_active_image_updated.standard", function(e) {

				if (e.changedBy === "startup") {

					revealNextImg(e.prevImgObj, e.activeImgObj);

				} else {

					slideshow.images.showLoader(e.prevImgObj);

					preloadImages(e);

				}

			});

			dom.document.on("slideshow_preload_image_complete.standard", function(e) {

				var isInitialSet = e.prevImgObj === undefined;

				if (e.activeImgObj.index === e.imgLoadedObj.index && !isInitialSet) {

					revealNextImg(e.prevImgObj, e.activeImgObj);

				}

			});

		},

		preloadImages = function(e) {

			slideshow.images.preloadImages({
				targetIdx: e.activeImgObj.index
			});

		},

		hideImgDuringTransition = function(effect) {

			// Only certain transition effects require the image to be hidden
			// (in addition to the wrapper).

			var doNotHideImgList = ["crossfade", "cut", "seamlessfade"];

			return $.inArray(effect, doNotHideImgList) === -1;

		},

		revealNextImg = function(prev, next){

			var effect = getTransitionEffect(next.index, "reveal"),
				duration = settings.effectDuration,
				isFirstImg = prev === undefined,
				prevImage = !isFirstImg ? prev.largeImage : undefined,
				nextImage = next.largeImage,
				deprecatedTransitions = [
					"blinds",
					"borderreveal",
					"barhorizontalrandomreveal",
					"checkerboard",
					"bubblereveal"
				];

			if (_.contains(deprecatedTransitions, effect)) {

				effect = "fade";

			}

			if (!isFirstImg) {

				slideshow.images.hideLoader(prev);

			}

			core.triggerCustomEvent({
				type: "slideshow_transition_start"
			});

			if (slideshow.hasReflection()) {

				if (!isFirstImg) {

					prevImage = prevImage.add(prev.reflectionWrapper);

				}

				nextImage = nextImage.add(next.reflectionWrapper);

			}

			if (hideImgDuringTransition(effect)) {

				// next.largeImage.hide();
				nextImage.hide();

			}

			switch (effect) {

				case "fade":

					if (isFirstImg) {

						next.wrapper.show();

						/*next.largeImage.show("fade", {}, duration, function() {
							processTransitionComplete(prev, next);
						});*/

						$.when(nextImage.show("fade", {}, duration)).then(function() {

							processTransitionComplete(prev, next);

						});

					} else {

						/*prev.largeImage.hide("fade", {}, duration * 0.25, function() {

							prev.wrapper.hide();
							next.wrapper.show();

							next.largeImage.show("fade", {}, duration * 0.75, function() {
								processTransitionComplete(prev, next);
							});

						});*/

						$.when(prevImage.hide("fade", {}, duration * 0.25)).then(function() {

							prev.wrapper.hide();
							next.wrapper.show();

							$.when(nextImage.show("fade", {}, duration * 0.75)).then(function() {

								processTransitionComplete(prev, next);

							});

						});
					}

					break;

				case "cut":

					if (isFirstImg) {

						next.wrapper.css({ display: "block" });
						processTransitionComplete(prev, next);

					} else {

						prev.wrapper.css({ display: "none" });
						next.wrapper.css({ display: "block" });
						processTransitionComplete(prev, next);
					}

					break;

				case "seamlessfade":

					if (isFirstImg) {

						next.wrapper.css({ opacity: 0, display: "inline-block" }).animate({ opacity: 1 }, duration, function(){
							processTransitionComplete(prev, next);
						});

					} else {

						prev.wrapper.css({ zIndex: 0 });

						next.wrapper.css({ zIndex: 10, opacity: 0, display: "inline-block" }).animate({ opacity: 1 }, duration, function(){

							prev.wrapper.css({ opacity: 0 });
							processTransitionComplete(prev, next);

						});

					}

					break;

				case "crossfade":

					if (isFirstImg) {

						next.wrapper.css({ opacity: 0, display: "inline-block" }).animate({ opacity: 1 }, duration, function(){
							processTransitionComplete(prev, next);
						});

					} else {

						prev.wrapper.animate({ opacity: 0 }, duration, function(){
							processTransitionComplete(prev, next);
						});

						next.wrapper.css({ opacity: 0, display: "inline-block" }).animate({ opacity: 1 }, duration);

					}

					break;

				case "blinds":

					next.largeImage.css("opacity", 0);

					if (isFirstImg) {

						next.wrapper.show();
						next.largeImage.show("blinds", { direction: "horizontal" }, duration * 0.75, function() {
							processTransitionComplete(prev, next);
						});

					} else {

						prev.largeImage.hide("fade", {}, duration * 0.25, function() {

							prev.wrapper.hide();
							next.wrapper.show();
							next.largeImage.show("blinds", { direction: "horizontal" }, duration * 0.75, function() {
								processTransitionComplete(prev, next);
							});

						});

					}

					break;

				case "borderreveal":

					next.largeImage.css({ opacity: 0 });

					if (isFirstImg) {

						next.wrapper.show();
						next.largeImage.show("borderReveal", { direction: "horizontal" }, duration * 0.75, function() {
							processTransitionComplete(prev, next);
						});

					} else {

						prev.largeImage.hide("fade", {}, duration * 0.25, function() {

							prev.wrapper.hide();
							next.wrapper.show();
							next.largeImage.show("borderReveal", { direction: "horizontal" }, duration * 0.75, function() {
								processTransitionComplete(prev, next);
							});

						});

					}

					break;

				case "barhorizontalrandomreveal":

					next.largeImage.css("opacity", 0);

					if (isFirstImg) {

						next.wrapper.show();
						next.largeImage.show("barHorizontalRandomReveal", { direction: "horizontal" }, duration * 0.75, function() {
							processTransitionComplete(prev, next);
						});

					} else {

						prev.largeImage.hide("fade", {}, duration * 0.25, function() {

							prev.wrapper.hide();
							next.wrapper.show();
							next.largeImage.show("barHorizontalRandomReveal", { direction: "horizontal" }, duration * 0.75, function() {
								processTransitionComplete(prev, next);
							});

						});

					}

					break;

				case "checkerboard":

					next.largeImage.css("opacity", 0);

					if (isFirstImg) {

						next.wrapper.show();
						next.largeImage.show("checkerBoard", { direction: "horizontal" }, duration * 0.75, function() {
							processTransitionComplete(prev, next);
						});

					} else {

						prev.largeImage.hide("fade", {}, duration * 0.25, function() {

							prev.wrapper.hide();
							next.wrapper.show();
							next.largeImage.show("checkerBoard", { direction: "horizontal" }, duration * 0.75, function() {
								processTransitionComplete(prev, next);
							});

						});

					}

					break;

				case "bubblereveal":

					next.largeImage.css("opacity", 0);

					if (isFirstImg) {

						next.wrapper.show();
						next.largeImage.show("bubbleReveal", { direction: "horizontal", easing: "linear" }, duration * 0.75, function() {
							processTransitionComplete(prev, next);
						});

					} else {

						prev.largeImage.hide("fade", {}, duration * 0.25, function() {

							prev.wrapper.hide();
							next.wrapper.show();
							next.largeImage.show("bubbleReveal", { direction: "horizontal", easing: "linear" }, duration * 0.75, function() {
								processTransitionComplete(prev, next);
							});

						});

					}

					break;

				case "wipetop": // clip

					if (isFirstImg) {

						next.wrapper.show();
						next.largeImage.show("clip", { direction: "horizontal" }, duration, function() {
							processTransitionComplete(prev, next);
						});

					} else {

						prev.largeImage.hide("fade", {}, duration * 0.25, function() {

							prev.wrapper.hide();
							next.wrapper.show();
							next.largeImage.show("clip", {direction: "horizontal"}, duration * 0.75, function() {
								processTransitionComplete(prev, next);
							});

						});

					}

					break;

				case "slideleft": // slide

					if (isFirstImg) {

						next.wrapper.show();
						next.largeImage.show("slide", { direction: "left" }, duration, function() {
							processTransitionComplete(prev, next);
						});

					} else {

						prev.largeImage.hide("fade", {}, duration * 0.25, function() {

							prev.wrapper.hide();
							next.wrapper.show();
							next.largeImage.show("slide", { direction: "left"}, duration * 0.75, function() {
								processTransitionComplete(prev, next);
							});

						});

					}

					break;

				case "zoom":

					var nextImgData = next,
						contentHeight = dom.imageTarget.height(),
						contentWidth = dom.imageTarget.width(),
						centerWidth = (contentWidth / 2),
						centerHeight = (contentHeight / 2),
						$nextWrapper = nextImgData.wrapper,
						$nextImg = nextImgData.largeImage,
						jQueryBugOffsetHeight = nextImgData.scaledWrapperDimensions.borderY + nextImgData.scaledWrapperDimensions.padY,
						jQueryBugOffsetWidth = nextImgData.scaledWrapperDimensions.borderX + nextImgData.scaledWrapperDimensions.padX,
						nextDimensions = next.scaledImgDimensions,
						nextHeight = nextDimensions.height + jQueryBugOffsetHeight,
						nextWidth = nextDimensions.width + jQueryBugOffsetWidth,
						alignVal = settings.align,
						padWidth = nextImgData.scaledWrapperDimensions.padX / 2,
						padHeight = nextImgData.scaledWrapperDimensions.padY / 2,
						borderWidth = nextImgData.scaledWrapperDimensions.borderX / 2,
						borderHeight = nextImgData.scaledWrapperDimensions.borderY / 2,
						alignSettings = {
							center: {
								left: ((contentWidth - nextWidth) / 2)
							},
							left: {
								left: 0
							},
							right: {
								left: (contentWidth - nextWidth)
							}
						},
						alignSettings = alignSettings[alignVal];
						$nextImg.css({ display: "inline-block", opacity: 0 });

						if(!isFirstImg){
							var prevImgData = prev,
							$prevWrapper = prevImgData.wrapper;
							$prevWrapper.fadeOut(200);
						}

						$nextWrapper.css({
							height: 0,
							width: 0,
							top: centerHeight,
							left: centerWidth,
							display: "inline-block",
							position: "absolute",
							padding: 0,
							borderWidth: 0
						});

						$nextWrapper.animate({
							height: nextHeight,
							width: nextWidth,
							top: (contentHeight - nextHeight) / 2,
							left: alignSettings.left,
							paddingLeft: padWidth,
							paddingRight: padWidth,
							paddingTop: padHeight,
							paddingBottom: padHeight,
							borderLeftWidth: borderWidth,
							borderRightWidth: borderWidth,
							borderTopWidth: borderHeight,
							borderBottomWidth: borderHeight
						}, duration, function(){
							$nextImg.animate({
								opacity: 1
							});
							processTransitionComplete(prev, next);
						});

					break;

				case "resize":

					var nextImgData = next,
						$nextWrapper = nextImgData.wrapper,
						$nextImg = nextImgData.largeImage,
						contentWidth = dom.imageTarget.width(),
						contentHeight = dom.imageTarget.height(),
						nextWidth = nextImgData.scaledWrapperDimensions.width,
						nextHeight = nextImgData.scaledWrapperDimensions.height,
						alignVal = settings.align,
						alignSettings = {
							center: {
								startingLeftEdge: (contentWidth - nextWidth) / 2,
								startingTopEdge: (contentHeight - nextHeight) / 2
							},
							left: {
								startingLeftEdge: 0,
								endingLeftEdge: 0,
								startingTopEdge: (contentHeight - nextHeight) / 2
							},
							right: {
								startingLeftEdge: contentWidth - nextWidth,
								startingTopEdge: (contentHeight - nextHeight) / 2
							}
						};

					$nextWrapper.css({
						// background: "blue",
						position: "absolute",
						overflow: "hidden",
						display: "none"
					});

					if(alignVal === "center"){
						$nextWrapper.css({
							marginLeft: "auto",
							marginRight: "auto"
						});
					}

					$nextImg.css({
						display: "inline-block",
						opacity: 0
					});

					if (isFirstImg) {

						alignSettings = alignSettings[alignVal];

						$nextWrapper.css({
							left: alignSettings.startingLeftEdge,
							display: "inline-block",
							width: nextWidth,
							height: nextHeight,
							top: (contentHeight - nextHeight) / 2
						});

						/*$nextImg.animate({ opacity: 1 }, duration, function(){
							processTransitionComplete(prev, next);
						});*/

						$nextImg.animate(
							{opacity: 1},
							{
								"duration": duration,
								step: function(now, fx) {

									dom.document.trigger({ type: "resize_animation_reveal_step", "now": now, "fx": fx });

								},
								complete: function() {

									processTransitionComplete(prev, next);

								}
							}
						);


					} else {

						var prevImgData = prev,
							$prevWrapper = prevImgData.wrapper,
							$prevImg = prevImgData.largeImage,
							prevWidth = prevImgData.scaledWrapperDimensions.width,
							prevHeight = prevImgData.scaledWrapperDimensions.height;

							$prevWrapper.css({
								// background: "red",
								position: "absolute",
								overflow: "hidden",
								display: "inline-block"
							});

						alignSettings.center = {
							startingLeftEdge: (contentWidth - prevWidth) / 2,
							startingTopEdge: (contentHeight - prevHeight) / 2,
							endingLeftEdge: (contentWidth - nextWidth) / 2,
							endingTopEdge: (contentHeight - nextHeight) / 2
						};

						alignSettings.right = {
							startingLeftEdge: contentWidth - prevWidth,
							startingTopEdge: (contentHeight - prevHeight) / 2,
							endingLeftEdge: contentWidth - nextWidth,
							endingTopEdge: (contentHeight - nextHeight) / 2
						};

						alignSettings.left = {
							startingLeftEdge: 0,
							startingTopEdge: (contentHeight - prevHeight) / 2,
							endingLeftEdge: 0,
							endingTopEdge: (contentHeight - nextHeight) / 2
						};

						alignSettings = alignSettings[alignVal];

						var jQueryBugOffsetWidth = nextImgData.scaledWrapperDimensions.borderX + nextImgData.scaledWrapperDimensions.padX,
							jQueryBugOffsetHeight = nextImgData.scaledWrapperDimensions.borderY + nextImgData.scaledWrapperDimensions.padY;

						$nextWrapper.css({
							width: prevWidth + jQueryBugOffsetWidth,
							height: prevHeight,
							left: $prevWrapper.css("left"),
							top: $prevWrapper.css("top")
						});

						$prevImg.fadeOut(duration * 0.4, function(){

							$nextWrapper.show();
							$prevWrapper.hide();

							$nextWrapper.animate({
								width: nextWidth,
								left: alignSettings.endingLeftEdge
							}, duration * 0.6, function(){

								$nextWrapper.css({
									height: prevHeight + jQueryBugOffsetHeight
								});

								$nextWrapper.animate({
									height: nextHeight,
									top: alignSettings.endingTopEdge
								}, duration * 0.6, function(){

									$nextImg.animate(
										{opacity: 1},
										{
											"duration": duration * 0.4,
											step: function(now, fx) {

												dom.document.trigger({ type: "resize_animation_reveal_step", "now": now, "fx": fx });

											},
											complete: function() {

												processTransitionComplete(prev, next);

											}
										}
									);

								});

							});

						});
					}

					break;

			}

		},

		processTransitionComplete = function(prevImgObj, activeImgObj) {

			core.triggerCustomEvent({
				type: "slideshow_transition_complete",
				"prevImgObj": prevImgObj,
				"activeImgObj": activeImgObj
			});

		},

		positionWrappers = function() {

			$.each(settings.images, function(i, el) {

				sizeWrapper(el);
				positionWrapper(el);

			});

		},

		getWrapperWidth = function($wrapper, imgWidth) {

			if (slideshow.hasReflection()) {

				return dom.imageTarget.width();

			} else {

				return imgWidth + slideshow.images.getBorderPadSize($wrapper, true, "width");

			}

		},

		getWrapperHeight = function($wrapper, imgHeight) {

			if (slideshow.hasReflection()) {

				return dom.imageTarget.height();

			} else {

				return imgHeight + slideshow.images.getBorderPadSize($wrapper, true, "height");

			}

		},

		sizeWrapper = function(imgObj) {

			var $wrapper  = imgObj.wrapper,
				wrapperWidth = getWrapperWidth(imgObj.wrapper, imgObj.scaledImgDimensions.width),
				wrapperHeight = getWrapperHeight(imgObj.wrapper, imgObj.scaledImgDimensions.height);

			$wrapper.css({
				width: wrapperWidth,
				height: wrapperHeight
			});

			imgObj.scaledWrapperDimensions = {
				width: wrapperWidth,
				height: wrapperHeight,
				borderX: slideshow.images.getBorderSize($wrapper, true, "width"),
				borderY: slideshow.images.getBorderSize($wrapper, true, "height"),
				padX: slideshow.images.getPadSize($wrapper, true, "width"),
				padY: slideshow.images.getPadSize($wrapper, true, "height")
			};

		},

		positionWrapper = function(imgObj) {

			var $wrapper = imgObj.wrapper,
				wrapperWidth = imgObj.scaledWrapperDimensions.width,
				wrapperHeight = imgObj.scaledWrapperDimensions.height,
				halfWrapperWidth = wrapperWidth / 2,
				halfWrapperHeight = wrapperHeight / 2,
				$mainImgWrapper = imgObj.mainImgWrapper,
				$mainImg = imgObj.largeImage,
				$reflectionImg = imgObj.reflectionImage,
				$reflectionOverlay = imgObj.reflectionOverlay,
				mainImgWidth = imgObj.scaledImgDimensions.width,
				mainImgHeight = imgObj.scaledImgDimensions.height,
				mainImgHeightOffset = imgObj.scaledImgDimensions.heightOffset === undefined ? 0 : imgObj.scaledImgDimensions.heightOffset,
				halfMainImgWidth = mainImgWidth / 2,
				halfMainImgHeight = mainImgHeight / 2,
				isCenterAligned = settings.align === "center",
				isLeftAligned = settings.align === "left",
				isRightAligned = settings.align === "right",
				effect = getTransitionEffect(imgObj.index),
				isZoom = effect === "zoom",
				isResize = effect === "resize",
				topOffset,
				leftOffset;

			if(!isZoom && !isResize){ // zoom and resize handle centering a bit differently (below)

				if (slideshow.hasReflection()) {

					$mainImgWrapper.css({
						bottom: settings.reflectionHeight
					});

					// Handle fill support.
					if (mainImgHeightOffset <= 0) {

						$mainImg.css({
							bottom: 0,
							top: "auto",
							marginTop: "auto"
						});

						$reflectionImg.css({
							top: 0
						});

					} else {

						$mainImg.css({
							bottom: "auto",
							top: "50%",
							marginTop: -halfMainImgHeight
						});

						$reflectionImg.css({
							top: -mainImgHeightOffset
						});

					}

				}

				if(isCenterAligned){

					if (slideshow.hasReflection()) {

						$mainImg
							.add($reflectionImg)
							.add($reflectionOverlay)
							.css({
								marginLeft: -halfMainImgWidth
							});

					} else {

						$wrapper.css({ // fancy css to center a div within its container, vertically and horizontally
							top: "50%",
							left: "50%",
							marginLeft: -halfWrapperWidth,
							marginTop: -halfWrapperHeight
						});

					}

				}
				else if(isLeftAligned){

					if (!slideshow.hasReflection()) {

						$wrapper.css({ // this is fancy css to center a div vertically within its container
							top: "50%",
							marginTop: -halfWrapperHeight
						});

					}

				}
				else if(isRightAligned){

					if (!slideshow.hasReflection()) {

						$wrapper.css({
							top: "50%",
							marginTop: -halfWrapperHeight,
							marginLeft: dom.imageTarget.width() - wrapperWidth
						});

					}

				}

			}

			else { // effect is zoom or resize

				topOffset = (dom.imageTarget.height() - wrapperHeight) / 2;
				$wrapper.css({ top: topOffset < 0 ? 0 : topOffset });
				if(isCenterAligned){
					leftOffset = (dom.imageTarget.width() - wrapperWidth) / 2;
					$wrapper.css({ left: leftOffset < 0 ? 0 : leftOffset });
				}
				else if(isRightAligned){
					leftOffset = dom.imageTarget.width() - wrapperWidth;
					$wrapper.css({ left: leftOffset < 0 ? 0 : leftOffset });
				}

			}

		},

		getTransitionEffect = function(idx, invokedBy) {

			var effect = settings.effect;

			if (settings.initialEffect !== effect && idx === 0) {

				effect = settings.initialEffect;

				if (invokedBy === "reveal") {

					settings.initialEffect = settings.effect;

				}

			}

			return effect;

		},

		destroy = function() {

			dom.document.off(".standard");

		};

	api.init = function() {

		saveSettings();

		setupDom();

		addEventHandlers();

		setupModules();

		slideshow.addCleanupItem(destroy);

		slideshow.triggerModuleReady("standard");

	};

}(window.slideshow.standard = window.slideshow.standard || {}, jQuery));
}).call(global, module, undefined, undefined);
