'use strict';

var $ = (typeof window !== "undefined" ? window.jQuery : typeof global !== "undefined" ? global.jQuery : null);
var dom = require('portfolio-dom');
var formWidget = require('form-widget');

var scrollApi;

var showThankYou = function() {

	var $popup = $('<div class="form-popup vert-horiz-center">' +
		formWidget.submissionHeaderText +
		'<br><br>' +
		formWidget.submissionMsgText +
		'</div>');

	formWidget.form.prepend($popup);

	window.setTimeout(function() {

		$popup.remove();
		formWidget.reset();

	}, 3000);

};

var setFormHeight = function() {

	if (dom.body.hasClass('image_count_0')) {

		formWidget.form.height(dom.content.height() - (formWidget.form.outerHeight(true) - formWidget.form.height()));

	} else {

		formWidget.form.height(scroller.dom.positioner.height() - (formWidget.form.position().top - parseInt(scroller.dom.positioner.css('paddingTop'), 10)) - (formWidget.form.outerHeight(true) - formWidget.form.height()));

	}

};

var refreshScroller = function() {

	setFormHeight();
	scrollApi.reinitialise();

};

var destroy = function() {

	dom.window.off('.form-scroller');
	dom.document.off('.form-scroller');

	scrollApi.destroy();
	formWidget.destroy();

};

module.exports = {

	init: function() {

		formWidget.init({
			elId: 'contact'
		});

		formWidget.form.on('fbWidget-form-submit-success', showThankYou);
		formWidget.form.on('fbWidget-familyfriends-field-added fbWidget-familyfriends-field-removed', refreshScroller);

		window.core.addUnload(destroy);

		formWidget.form.jScrollPane({
			verticalGutter: 10,
			hideFocus: true,
			// Hack to disable horizontal scroll bars.
			contentWidth: '0px'
		});

		scrollApi = formWidget.form.data('jsp');

		dom.document.on("core_css_hooks_added.form-scroller", function() {

			// Now that hooks have been applied and the template layout may
			// have adjusted, refresh the scroller and trigger a form widget
			// layout update via the resize event.

			refreshScroller();
			dom.window.trigger('resize');

		});

		dom.window.on('resize.form-scroller', refreshScroller);

	}

};