'use strict';

var dom = require('form-widget-dom');
var emitter = require('emitter');

var defaultText;
var $fileUpload;
var $fileInfo;

var clearFile = function() {

	$fileUpload.val('');
	$fileInfo.html(defaultText);

};

var setupDom = function() {

	// Setup cache.
	$fileUpload = dom.inputContainer.find('.fb-file-upload');
	$fileInfo = dom.inputContainer.find('.fb-file-info');

	// Add accessor properties for the cache to the dom module.
	/*Object.defineProperty(dom, 'fileUpload', {
		get: function() {

			return $fileUpload;

		}
	});

	Object.defineProperty(dom, 'fileInfo', {
		get: function() {

			return $fileInfo;

		}
	});*/

};

var addEventHandlers = function() {

	dom.fbForm.off('.fb-fileupload');

	dom.fbForm.on('click.fb-fileupload', '.fb-file-browse', function() {

		$fileUpload.click();

	});

	emitter.on('fbWidget-destroy', function() {

		$fileUpload = null;
		$fileInfo = null;

	});

};

var getFile = function() {

	return $fileUpload[0].files[0];

};

var showFileName = function() {

	$fileInfo.html(getFile().name);

};

var isFileSelected = function() {

	return !!$fileUpload.val();

};

module.exports = {

	init: function() {

		setupDom();
		addEventHandlers();
		defaultText = $fileInfo.html();

	},

	isFileuploadType: function($col) {

		return $col.hasClass('fb-fileupload-type');

	},

	getVal: function($col) {

		var val = '';

		if ($fileUpload[0].files.length) {

			val = getFile().name;

		}

		return {
			name: dom.getFieldTitleText($col),
			value: val
		};

	},

	validateFileSelection: function() {

		if (isFileSelected()) {

			showFileName();
			return true;

		}

		clearFile();
		return false;

	},

	validateFileSize: function(maxSize) {

		// Convert size to bytes.
		maxSize = maxSize * 1024 * 1024;

		if (isFileSelected()) {

			if (getFile().size > maxSize) {

				clearFile();
				return false;

			}

		}

		showFileName();
		return true;

	},

	validateFileType: function(acceptedTypes) {

		acceptedTypes = acceptedTypes.replace(/\s/g, '').split(',');

		if (acceptedTypes.indexOf(getFile().type) === -1) {

			clearFile();
			return false;

		}

		showFileName();
		return true;

	}

};