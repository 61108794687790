'use strict';

var $ = (typeof window !== "undefined" ? window.jQuery : typeof global !== "undefined" ? global.jQuery : null);
var domCache = require('dom-cache');

/*

container: selector or jQuery object for element you want to adjust scroll position - default 'html,body'
scrollTop: number of pixels to set scrollTop position to - default 0
scrollLeft: number of pixels to set scrollLeft position to - default 0
speed: number of ms for scroll animation - default 400

*/
module.exports = function(opts) {

	opts = opts || {};
	opts.speed = typeof opts.speed === 'undefined' ? 400 : opts.speed;
	opts.scrollTop = opts.scrollTop || 0;
	opts.scrollLeft = opts.scrollLeft || 0;

	var $container = opts.container ? $(opts.container) : domCache.html.add(domCache.body);

	return $container
		.stop()
		.animate({
			scrollTop: opts.scrollTop,
			scrollLeft: opts.scrollLeft
		}, opts.speed);

};